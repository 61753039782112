import * as WifiService from "../services/wifiBluetooth/wifiBluetoothConnection.service";
import { LOADER, WIFI } from "../shared/constants/actions.constants";
import { ERROR, SUCCESS } from "../shared/constants/messages.constants";
import { NETWORK_ERROR } from "../shared/constants/statusCodes.constants";
import * as FlashMessage from "../shared/utils/flashMessage";

/**
 * @desc To fetch all available WiFi networks and store them into Redux Store
 * @public
 */
export const getWifiNetworksList = (showLoader = true) => {
    return async (dispatch) => {
        try {
            // if (showLoader) {
            //     dispatch(setLoader(true));
            // }

            let { data } = await WifiService.getWifiNetworks();

            onSuccessWifiNetworksList(dispatch, data);
        } catch (error) {
            onErrorWifiNetworksList(dispatch, error);
        }
    }
}

/**
 * @desc To fetch WiFi status and store it into Redux Store
 * @public
 */
export const getWifiStatus = () => {
    return async (dispatch) => {
        try {
            // dispatch(setLoader(true));

            let { data } = await WifiService.getWifiStatus();

            onSuccessWifiStatus(dispatch, data);
        } catch (error) {
            onErrorWifiStatus(dispatch, error);
        }
    }
}


/**
 * @desc To fetch Bluetooth status and store it into Redux Store
 * @public
 */
export const getBluetoothStatus = () => {
    return async (dispatch) => {
        try {
            // dispatch(setLoader(true));

            let { data } = await WifiService.getBluetoothStatus();

            onSuccessBluetoothStatus(dispatch, data);
        } catch (error) {
            onErrorBluetoothStatus(dispatch, error);
        }
    }
}


/**
 * @desc To fetch WiFi connection status and store it into Redux Store
 * @public
 */
export const getWifiConnectionStatus = () => {
    return async (dispatch) => {
        try {
            // dispatch(setLoader(true));

            let { data } = await WifiService.getWifiConnectionStatus();

            onSuccessWifiConnectionStatus(dispatch, data);
        } catch (error) {
            onErrorWifiConnectionStatus(dispatch, error);
        }
    }
}

/**
 * @desc To fetch Bluetooth connection status and store it into Redux Store
 * @public
 */
export const getBluetoothConnectionStatus = () => {
    return async (dispatch) => {
        try {
            // dispatch(setLoader(true));

            let { data } = await WifiService.getBluetoothConnectionStatus();

            onSuccessBluetoothConnectionStatus(dispatch, data);
        } catch (error) {
            onErrorBluetoothConnectionStatus(dispatch, error);
        }
    }
}

/**
 * @desc To fetch Bluetooth networks list
 * @public
 */

export const getBluetoothNetworksList = () => {
    return async (dispatch) => {
        try {

            let { data } = await WifiService.getBluetoothNetworks();

            onSuccessBluetoothNetworksList(dispatch, data);
        } catch (error) {
            onErrorBluetoothNetworksList(dispatch, error);
        }
    }
}


/**
 * @desc To connect to a WiFi network
 * @public
 */
export const connectToWifi = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));

            const data = await WifiService.connectToWifi(payload);
            onSuccessWifiConnect(dispatch, data.data);
        } catch (error) {
            onErrorWifiConnect(dispatch, error);
        }
    }
}


/**
 * @desc To connect to a Bluetooth network
 * @public
 */
export const connectToBluetooth = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));

            const data = await WifiService.connectToBluetooth(payload);
            onSuccessBluetoothConnect(dispatch, data.data);
        } catch (error) {
            onErrorBluetoothConnect(dispatch, error);
        }
    }
}

/**
 * @desc To forget a WiFi network
 * @public
 */
export const forgetWifiConnection = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));

            const data = await WifiService.forgetWifiConnection(payload);
            onSuccessWifiForget(dispatch, data.data);
        } catch (error) {
            onErrorWifiForget(dispatch, error);
        }
    }
}

/**
 * @desc To forget a Bluetooth Device
 * @public
 */
export const forgetBluetoothConnection = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));

            const data = await WifiService.forgetBluetoothConnection(payload);
            onSuccessBluetoothForget(dispatch, data.data);
        } catch (error) {
            onErrorBluetoothForget(dispatch, error);
        }
    }
}

/**
 * @desc To manage WiFi status
 * @public
 */
export const manageWifiStatus = (isEnable) => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const data = await WifiService.manageWifi({enable: isEnable});
            onSuccessWifiManage(dispatch, data.data, isEnable);
        } catch (error) {
            onErrorWifiManage(dispatch, error);
        }
    }
}

/**
 * @desc To manage Bluetooth status
 * @public
 */
export const manageBluetoothStatus = (isEnable) => {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const data = await WifiService.manageBluetooth({enable: isEnable});
            onSuccessBluetoothManage(dispatch, data.data, isEnable);
        } catch (error) {
            onErrorBluetoothManage(dispatch, error);
        }
    }
}

/**
 * @desc To fetch WiFi networks list successfully
 * @param {object} dispatch
 * @param {Array} networks
 * @private
 */
const onSuccessWifiNetworksList = (dispatch, networks) => {
    dispatch(setWifiNetworksAction(networks));
    // dispatch(setLoader(false));
}

const onSuccessBluetoothNetworksList = (dispatch, networks) => {
    dispatch(setBluetoothNetworksAction(networks));
    // dispatch(setLoader(false));
}

/**
 * @desc To fetch WiFi status successfully
 * @param {object} dispatch
 * @param {boolean} status
 * @private
 */
const onSuccessWifiStatus = (dispatch, status) => {
    dispatch(setWifiStatusAction(status));
    // dispatch(setLoader(false));
}

/**
 * @desc To fetch Bluetooth status successfully
 * @param {object} dispatch
 * @param {boolean} status
 * @private
 */
const onSuccessBluetoothStatus = (dispatch, status) => {
    dispatch(setBluetoothStatusAction(status));
    // dispatch(setLoader(false));
}

/**
 * @desc To fetch WiFi connection status successfully
 * @param {object} dispatch
 * @param {boolean} isConnected
 * @private
 */
const onSuccessWifiConnectionStatus = (dispatch, isConnected) => {
    dispatch(setWifiConnectionStatusAction(isConnected));
    // dispatch(setLoader(false));
}

/**
 * @desc To fetch Bluetooth connection status successfully
 * @param {object} dispatch
 * @param {boolean} isConnected
 * @private
 */
const onSuccessBluetoothConnectionStatus = (dispatch, data) => {
    dispatch(setBluetoothConnectionStatusAction(data));
    // dispatch(setLoader(false));
}

/**
 * @desc To connect to WiFi successfully
 * @param {object} dispatch
 * @param {object} message
 * @private
 */
const onSuccessWifiConnect = (dispatch, message) => {
    dispatch(setLoader(false));
    FlashMessage.success(message || SUCCESS.WIFI_CONNECTED);
}

/**
 * @desc To connect to Bluetooth successfully
 * @param {object} dispatch
 * @param {object} message
 * @private
 */
const onSuccessBluetoothConnect = (dispatch, message) => {
    dispatch(setLoader(false));
    FlashMessage.success(message || SUCCESS.BLUETOOTH_CONNECTED);
}


/**
 * @desc To forget WiFi successfully
 * @param {object} dispatch
 * @param {object} message
 * @private
 */
const onSuccessWifiForget = (dispatch, message) => {
    dispatch(setLoader(false));
    FlashMessage.success(SUCCESS.WIFI_REMOVE);
}

/**
 * @desc To forget Bluetooth successfully
 * @param {object} dispatch
 * @param {object} message
 * @private
 */
const onSuccessBluetoothForget = (dispatch, message) => {
    dispatch(setLoader(false));
    FlashMessage.success(SUCCESS.BLUETOOTH_REMOVE);
}

/**
 * @desc To manage WiFi status successfully
 * @param {object} dispatch
 * @param {object} message
 * @private
 */
const onSuccessWifiManage = (dispatch, message) => {
    dispatch(setLoader(false));
    FlashMessage.success( message || SUCCESS.WIFI_MANAGED);
}

/**
 * @desc To manage Bluetooth status successfully
 * @param {object} dispatch
 * @param {object} message
 * @private
 */
const onSuccessBluetoothManage = (dispatch, message) => {
    dispatch(setLoader(false));
    FlashMessage.success( message || SUCCESS.BLUETOOTH_MANAGED);
}

/**
 * @desc To handle error while fetching WiFi networks list
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorWifiNetworksList = (dispatch, error) => {
    // dispatch(setLoader(false));
    if(error.message !== NETWORK_ERROR){
        FlashMessage.error(ERROR.WIFI_NETWORKS_FETCH_FAILED);
    }
}

const onErrorBluetoothNetworksList = (dispatch, error) => {
    // dispatch(setLoader(false));
    if(error.message !== NETWORK_ERROR){
        FlashMessage.error(ERROR.BLUETOOTH_DEVICES_FETCH_FAILED);
    }
}

/**
 * @desc To handle error while fetching WiFi status
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorWifiStatus = (dispatch, error) => {
    // dispatch(setLoader(false));
    if(error.message !== NETWORK_ERROR){
        FlashMessage.error(ERROR.WIFI_STATUS_FETCH_FAILED);
    }
}

/**
 * @desc To handle error while fetching Bluetooth status
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorBluetoothStatus = (dispatch, error) => {
    // dispatch(setLoader(false));
    if(error.message !== NETWORK_ERROR){
        FlashMessage.error(ERROR.BLUETOOTH_STATUS_FETCH_FAILED);
    }
}

/**
 * @desc To handle error while fetching WiFi connection status
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorWifiConnectionStatus = (dispatch, error) => {
    // dispatch(setLoader(false));
    if(error.message !== NETWORK_ERROR){
        dispatch(setWifiConnectionStatusAction(false));
    }
    // FlashMessage.error(ERROR.WIFI_CONNECTION_STATUS_FETCH_FAILED);
}

/**
 * @desc To handle error while fetching Bluetooth connection status
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorBluetoothConnectionStatus = (dispatch, error) => {
    // dispatch(setLoader(false));
    if(error.message !== NETWORK_ERROR){
        dispatch(setBluetoothConnectionStatusAction(false));
    }
    // FlashMessage.error(ERROR.BLUETOOTH_CONNECTION_STATUS_FETCH_FAILED);
}

/**
 * @desc To handle error while connecting to WiFi
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorWifiConnect = (dispatch, error) => {
    dispatch(setLoader(false));
    FlashMessage.error(ERROR.WIFI_CONNECT_FAILED);
}

/**
 * @desc To handle error while connecting to Bluetooth
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorBluetoothConnect = (dispatch, error) => {
    dispatch(setLoader(false));
    FlashMessage.error(ERROR.BLUETOOTH_CONNECT_FAILED);
}

/**
 * @desc To handle error while forgetting WiFi
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorWifiForget = (dispatch, error) => {
    dispatch(setLoader(false));
    FlashMessage.error(ERROR.WIFI_FORGET_FAILED);
}

/**
 * @desc To handle error while forgetting Bluetooth
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorBluetoothForget = (dispatch, error) => {
    dispatch(setLoader(false));
    FlashMessage.error(ERROR.BLUETOOTH_FORGET_FAILED);
}

/**
 * @desc To handle error while managing WiFi status
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorWifiManage = (dispatch, error) => {
    dispatch(setLoader(false));
    FlashMessage.error(ERROR.WIFI_MANAGE_FAILED);
}

/**
 * @desc To handle error while managing bluetooth status
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorBluetoothManage = (dispatch, error) => {
    dispatch(setLoader(false));
    FlashMessage.error(ERROR.BLUETOOTH_MANAGE_FAILED);
}

/**
 * @desc Action to set loader
 * @param {boolean} flag
 * @private
 */
const setLoader = (flag) => {
    return {
        type: LOADER.SET_LOADER,
        payload: flag
    }
}

/**
 * @desc Action to set WiFi networks list into store
 * @param {Array} networks
 * @private
 */
const setWifiNetworksAction = (networks = []) => {
    return {
        type: WIFI.SET_WIFI_NETWORKS_LIST,
        payload: networks
    }
}

/**
 * @desc Action to set Bluetooth networks list into store
 * @param {Array} networks
 * @private
 */
const setBluetoothNetworksAction = (networks = []) => {
    return {
        type: WIFI.SET_BLUETOOTH_DEVICES_LIST,
        payload: networks
    }
}

/**
 * @desc Action to set WiFi status into store
 * @param {boolean} status
 * @private
 */
const setWifiStatusAction = (status) => {
    return {
        type: WIFI.SET_WIFI_STATUS,
        payload: status
    }
}

/**
 * @desc Action to set Bluetooth status into store
 * @param {boolean} status
 * @private
 */
const setBluetoothStatusAction = (status) => {
    return {
        type: WIFI.SET_BLUETOOTH_STATUS,
        payload: status
    }
}

/**
 * @desc Action to set WiFi connection status into store
 * @param {boolean} isConnected
 * @private
 */
const setWifiConnectionStatusAction = (payload) => {
    return {
        type: WIFI.SET_WIFI_CONNECTION_STATUS,
        payload: payload
    }
}

/**
 * @desc Action to set Bluetooth connection status into store
 * @param {boolean} isConnected
 * @private
 */
const setBluetoothConnectionStatusAction = (payload) => {
    return {
        type: WIFI.SET_BLUETOOTH_CONNECTION_STATUS,
        payload: payload
    }
}