import { AUTHTYPE } from "../shared/constants/actions.constants"

const initialState = {
  loggedInUser: null,
  isAdmin: false
}

export default function(state = initialState, action) {
  switch (action.type) {

    case AUTHTYPE.SET_CURRENT_USER:
      return {
        ...state,
        loggedInUser: action.payload
      }

    case AUTHTYPE.SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload
      }

    case AUTHTYPE.UPDATE_CURRENT_USER_SETTINGS:
      let userSettings = state.loggedInUser && state.loggedInUser.settings ? state.loggedInUser.settings : null

      if (!userSettings) return { ...state }

      userSettings = { ...userSettings, ...action.payload }
      return {
        ...state,
        loggedInUser: { ...state.loggedInUser, settings: { ...userSettings } }
      }

    default:
      return state
  }
}
