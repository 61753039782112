import React from "react"
import { withNamespaces } from 'react-i18next'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'
import { Formik } from 'formik'
import { loginUser } from "../../actions/auth.action"
import { LoginForm, LoginSchema } from "../../models/login.model"

export const LoginPanel = ({ history, ...props }) => {

  /**
   * @desc To handle login form submittion and authenticate it.
   * @param {object} event
   */
  const onLoginFormSubmit = event => {
    props.loginUser(event, history)
  }

  return (
    <div className="h-100 d-flex justify-content-center">
      <div className="pre-login-form">
        <div className="logo">
            <img src="assets/images/logo.svg" alt="Eadietech Logo" />
        </div>
        <h2 className="text-center form-title">{props.t('WELCOME_TO_EADIE_TECH')}</h2>
        <Formik
          enableReinitialize = { true }
          initialValues = { LoginForm }
          validationSchema = { LoginSchema }
          onSubmit = { onLoginFormSubmit }>
            {
              ({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                <React.Fragment>
                  <form onSubmit = { handleSubmit }>
                    <div className="form-group">
                      <input id = "email" name = "email" type="text" 
                        className = { `form-control ${ (errors.email && touched.email) ? "is-invalid" : null }` } 
                        placeholder = { props.t('EMAIL') } 
                        onChange = { handleChange } 
                        value = { values.email } 
                      />
                        { errors.email && touched.email ? (
                          <div className="invalid-feedback">
                            { props.t('PLEASE_PROVIDE_A_VALID_EMAIL') }
                          </div>
                        ) : null }
                    </div>
                    <div className="form-group mg-b-xs-1">
                      <input id = "password" name = "password" type="password" 
                        className = { `form-control ${ (errors.password && touched.password) ? "is-invalid" : null }` } 
                        placeholder = { props.t('password') } 
                        onChange = { handleChange } 
                        value = { values.password } 
                      />
                        { errors.password && touched.password ? (
                          <div className="invalid-feedback">
                            { props.t('PLEASE_PROVIDE_A_VALID_PASSWORD') }
                          </div>
                        ) : null }
                    </div>
                    <div className="mg-b-xs-20">
                    </div>
                    <button type="submit" className="btn btn-block btn-red">
                      { props.t('LOGIN') }
                    </button>
                  </form>
                </React.Fragment>
              )
            }
        </Formik>
      </div>
    </div>    
  )
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loginUser
  }, dispatch)
)

export default connect(null, mapDispatchToProps)(withRouter(withNamespaces()(LoginPanel)))
