import * as FlashMessage from "../../shared/utils/flashMessage"
import { ERROR } from "../../shared/constants/messages.constants"
import CONFIG from "../../config"
import { updateActiveAssessment } from "../../actions/assessment.action"
import { ASSESSMENT_STATUSES } from "../../shared/constants/shared.constants";

export const onHmdAutoPause = (data) => {
    FlashMessage.error(ERROR.ASSESSMENT_HMD_AUTO_PAUSE)

    if (data.id && data.status) {
        CONFIG.Store.dispatch(updateActiveAssessment(data.id,{ status: ASSESSMENT_STATUSES.PAUSED}))
    }
}