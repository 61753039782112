export const notFoundChart = chartId => {
    
  const renderChartFrame = () => {
    const div = document.getElementById(
      `${chartId}`,
    ) 
    div.style = ''
    div.data = ''
    div.type = ''
    div.classList.add('img__centered')
    div.innerHTML = `<img src="/assets/images/noDataFound.png" alt="" className="w-100 mg-b-5"/>`
  };

  renderChartFrame();
};
