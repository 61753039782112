import Axios from "axios";
import { API_CONSTANTS } from "../../shared/constants/api.constants";

/**
 * @desc Get all available WiFi networks
 * @public
 */
export const getWifiNetworks = () => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.WIFI.GET_WIFI_NETWORKS);

            res({
                data: data.networks,
                statusCode: 200,
                message: "Successfully fetched WiFi networks"
            });
        } catch (error) {
            rej(error);
        }
    });
};

/**
 * @desc Get Bluetooth devices list
 * @public
 */
export const getBluetoothNetworks = () => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.BLUETOOTH.GET_BLUETOOTH_DEVICES);

            res({
                data: data.devices,
                statusCode: 200,
                message: "Successfully fetched Bluetooth devices"
            });
        } catch (error) {
            rej(error);
        }
    });
}

/**
 * @desc Get WiFi status
 * @public
 */
export const getWifiStatus = () => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.WIFI.GET_WIFI_STATUS);

            res({
                data: data.wifi_enabled,
                statusCode: 200,
                message: "Successfully fetched WiFi status"
            });
        } catch (error) {
            rej(error);
        }
    });
};

/**
 * @desc Get Bluetooth status
 * @public
 */
export const getBluetoothStatus = () => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.BLUETOOTH.GET_BLUETOOTH_STATUS);

            res({
                data: data.bluetooth_enabled,
                statusCode: 200,
                message: "Successfully fetched WiFi status"
            });
        } catch (error) {
            rej(error);
        }
    });
};

/**
 * @desc Get WiFi connection status
 * @public
 */
export const getWifiConnectionStatus = () => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.WIFI.IS_WIFI_CONNECTED);

            res({
                data: data,
                statusCode: 200,
                message: "Successfully fetched WiFi connection status"
            });
        } catch (error) {
            rej(error);
        }
    });
};

/**
 * @desc Get WiFi connection status
 * @public
 */
export const getBluetoothConnectionStatus = () => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.BLUETOOTH.IS_BLUETOOTH_CONNECTED);

            res({
                data: data,
                statusCode: 200,
                message: "Successfully fetched WiFi connection status"
            });
        } catch (error) {
            rej(error);
        }
    });
};

/**
 * @desc Connect to a WiFi network
 * @public
 */
export const connectToWifi = (payload) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.WIFI.MANAGE_CONNECTION, payload);

            res({
                data: data.message,
                statusCode: data.status === "success" ? 200 : 400,
                message: data.message
            });
        } catch (error) {
            rej(error);
        }
    });
};

/**
 * @desc Connect to a Bluetooth network
 * @public
 */
export const connectToBluetooth = (payload) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.BLUETOOTH.MANAGE_BLUETOOTH_CONNECTION, payload);

            res({
                data: data.message,
                statusCode: data.status === "success" ? 200 : 400,
                message: data.message
            });
        } catch (error) {
            rej(error);
        }
    });
};

/**
 * @desc Forget a WiFi network
 * @public
 */
export const forgetWifiConnection = (payload) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.WIFI.FORGET_CONNECTION, payload);

            res({
                data: data.message,
                statusCode: data.status === "success" ? 200 : 400,
                message: data.message
            });
        } catch (error) {
            rej(error);
        }
    });
};

/**
 * @desc Forget a Bluetooth network
 * @public
 */
export const forgetBluetoothConnection = (payload) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.BLUETOOTH.REMOVE_BLUETOOTH_DEVICE, payload);

            res({
                data: data.message,
                statusCode: data.status === "success" ? 200 : 400,
                message: data.message
            });
        } catch (error) {
            rej(error);
        }
    });
};

/**
 * @desc Manage WiFi status
 * @public
 */
export const manageWifi = (payload) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.WIFI.MANAGE_WIFI, payload);

            res({
                data: data.message,
                statusCode: data.status === "success" ? 200 : 400,
                message: data.message
            });
        } catch (error) {
            rej(error);
        }
    });
};

/**
 * @desc Manage Bluetooth status
 * @public
 */
export const manageBluetooth = (payload) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.BLUETOOTH.MANAGE_BLUETOOTH, payload);

            res({
                data: data.message,
                statusCode: data.status === "success" ? 200 : 400,
                message: data.message
            });
        } catch (error) {
            rej(error);
        }
    });
};
