import i18n from "i18next"
import { reactI18nextModule } from "react-i18next"

import translationEn from "./locales/en-ca.json"
import translationFr from "./locales/fr-ca.json"

i18n
  .use(reactI18nextModule) 
  .init({
    resources: {
      "en": {
        translations: translationEn
      },
      "fr": {
        translations: translationFr
      }
    },
    lng: "en",
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    ns: ["translations"],
    defaultNS: "translations"
  })

export default i18n
