export const ROUTES = { 
    HOME: "/",
    LOGIN: "/sign-in"
}

export const HOMEPAGE_ROUTES = [
    ROUTES.HOME
]

export const DYNAMIC_ROUTES = {
    ASSESSMENT: (patientId) =>  `/patients/${patientId}/assessment`,
    ASSESSMENT_WITH_RECALLIBRATION: (patientId, recallibrateId) => `/patients/${patientId}/assessment?recalibrateId=${recallibrateId}`,
    USER_DETAILS: (userId) =>  `/users/${userId}`,
    INITIATE_ASSESSMENT: (patientId, assessmentId) =>  `/patients/${patientId}/assessments/${assessmentId}/initiate`,
    ASSESSMENT_REPORTS: (patientId) =>  `/patients/${patientId}/reports`,
    REPORTS_BY_ASSESSMENT_ID: (patientId, assessmentId) =>  `/patients/${patientId}/reports?assessmentId=${assessmentId}`
}