import { WIFI } from "../shared/constants/actions.constants";

const initialState = {
  wifiList: [],
  bluetoothList: [],
  wifiStatus: false,
  bluetoothStatus: false,
  isWifiConnected: false,
  isBluetoothConnected: false,
  connectedSSID: "",
  connectedBluetoothDevice: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case WIFI.SET_WIFI_NETWORKS_LIST:
      return {
        ...state,
        wifiList: action.payload
      };
    
    case WIFI.SET_BLUETOOTH_DEVICES_LIST:
      return {
        ...state,
        bluetoothList: action.payload
      };

    case WIFI.SET_WIFI_STATUS:
      return {
        ...state,
        wifiStatus: action.payload
      };

    case WIFI.SET_BLUETOOTH_STATUS:
      return {
        ...state,
        bluetoothStatus: action.payload
      };
    
    case WIFI.SET_WIFI_CONNECTION_STATUS:
      const { is_wifi_connected, ssid } = action.payload;
      return {
        ...state,
        isWifiConnected: is_wifi_connected,
        connectedSSID: ssid
      };

    case WIFI.SET_BLUETOOTH_CONNECTION_STATUS:
      const { connected, devices } = action.payload;
      return {
        ...state,
        isBluetoothConnected: connected,
        connectedBluetoothDevice: devices
      };
    
    default:
      return state;
  }
}
