import { HMDDEVICES } from "../shared/constants/actions.constants"

const initialState = {
  selectedHmdDevice: null,
  hmdDeviceList: [],
  hmdById: null,
  hmdAssignedAssessment: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case HMDDEVICES.SELECT_HMD_DEVICE:
        return {
            ...state,
            selectedHmdDevice: action.payload
        }

    case HMDDEVICES.SET_HMD_LIST:
        return {
            ...state,
            hmdDeviceList: [ ...action.payload ]
        }
    
    case HMDDEVICES.SET_HMD_DEVICE_BY_ID:
      if(action.payload){
        const deviceDetails = action.payload
        const deviceIndex = state.hmdDeviceList.findIndex((device) => device.id == deviceDetails.id)
        if(deviceIndex>-1){
          state.hmdDeviceList[deviceIndex] = action.payload;
        } else {
          state.hmdDeviceList[0] = action.payload;
        }

        return {
            ...state,
            hmdDeviceList: [ ...state.hmdDeviceList ],
            hmdById: action.payload && action.payload.hmd ? action.payload.hmd : null,
            hmdAssignedAssessment: action.payload && action.payload.assessment ? action.payload.assessment : null,
        }
      } else {
        return {
          ...state,
          hmdById: null,
          hmdAssignedAssessment: null
        }
      }

    case HMDDEVICES.ADD_HMD:
        const device = action.payload
        return {
            ...state,
            hmdDeviceList: [ ...state.hmdDeviceList, device ]
          }
    
    case HMDDEVICES.DELETE_HMD:
        const deviceId = action.payload
        const deletedDeviceIndex = state.hmdDeviceList.findIndex((device) => device.id == deviceId)
        state.hmdDeviceList.splice(deletedDeviceIndex, 1)

        return {
          ...state,
          hmdDeviceList: [ ...state.hmdDeviceList ]
        }
    
    default:
      return state
  }
}
