import { ASSESSMENT } from "../shared/constants/actions.constants"

const initialState = {
  activeAssessment: null,
  activeAssessmentProcessedData: null,
  activeAssessmentsByPatient: null,
  trialInProgress: false
}

export default function(state = initialState, action) {

  switch (action.type) {

    case ASSESSMENT.ADD_ACTIVE_ASSESSMENT:
      /*
      1. left -> os
      2. right -> od
      3. left then right -> initial left, then when os, od both exist then right 
      4. right then left -> initial right, then when os, od both exist then left 
      5. binocular -> not handled right now (As per Vivian)
      */
      const eye = action.payload?.configuration?.eye;
      const onGoingTestType = {
        'left': 'left',
        'right': 'right',
        'left then right': action.payload?.data?.clusterMd?.OS && action.payload?.data?.clusterMd?.OD ? 'right' : 'left',
        'right then left':  action.payload?.data?.clusterMd?.OS && action.payload?.data?.clusterMd?.OD ? 'left' : 'right',
      }

      return {
        ...state,
        activeAssessment: {
          ...action.payload, 
          ...(eye && {onGoingTestType: onGoingTestType[eye]})
        },
      }

    case ASSESSMENT.ADD_ACTIVE_ASSESSMENT_PROCESSED_DATA:
      return {
        ...state,
        activeAssessmentProcessedData: action.payload,
        trialInProgress: action.payload ? true: false
      }

    case ASSESSMENT.ADD_ACTIVE_ASSESSMENTS_BY_PATIENT_ID:
      return {
        ...state,
        activeAssessmentsByPatient: action.payload
      }

    case ASSESSMENT.UPDATE_ACTIVE_ASSESSMENTS_STATUS:
      let { assessmentId } = action.payload

      if (state.activeAssessment && state.activeAssessment.id && state.activeAssessment.id === assessmentId) {
        return {
          ...state,
          activeAssessment: { ...state.activeAssessment, ...action.payload },
          trialInProgress: false
        }
      } else {
        return {
          ...state,
          activeAssessment: { ...state.activeAssessment, ...action.payload },
          trialInProgress: false
        }
      }

    default:
      return state
  }
}
