import axios from 'axios'
import { getAuthToken, clearAuthToken, getAuthRefreshToken } from "../services/localstorage/localStorage.service"
import { STATUS_CODES, ROUTES } from "../shared/constants"
import * as LocalStorageService from "../services/localstorage/localStorage.service"

let inProgress = false;

axios.interceptors.request.use((config) => {
    const token = getAuthToken()
    config.headers = {
        ...config.headers,
        "authtoken": `${token}` ,
        "Content-Type": "application/json"
    }

    return config
}, (error) => {
    return Promise.reject(error)
})

axios.interceptors.response.use((response) => {
    return response
}, async (error) => {
    const baseUrl = error.config.url.split('/v1')?.[0] + '/v1'
    const authrefreshtoken = getAuthRefreshToken();
    const code = error && error.response && error.response.data && error.response.data.statusCode ? error.response.data.statusCode.split('-')?.[1] : 0;
    if (!inProgress && authrefreshtoken && code && STATUS_CODES.AUTH_TOKEN_EXPIRATION_CODES.includes(Number(code))) {
        try {
            inProgress = true;

            const rs = await axios.post(baseUrl + "/users/refresh-token", {}, {
                headers: {
                    "authrefreshtoken": `${authrefreshtoken}`
                }
            });

            const { token, refreshToken } = rs?.data?.data;

            LocalStorageService.setAuthToken(token)
            LocalStorageService.setAuthRefreshToken(refreshToken)
            
            inProgress = false;

            window.location.reload();
        } catch (_error) {
            clearAuthToken()
            window.location.href = ROUTES.LOGIN
        }
    } else {
        return Promise.reject(error)
    }
})
