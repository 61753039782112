import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setModalWithType } from "../../../actions/modal.action"
import { updateAssessment } from "../../../actions/assessment.action"
import { AssessmentCalibrationForm, AssessmentCalibrationSchema } from "../../../models/assessment-calibration.model"
import Slider from 'rc-slider';
import { Formik } from 'formik';

export const CalibrateModal = (props) => {
    const [calibration, setCalibration] = useState(AssessmentCalibrationForm)

    useEffect(() => {
        if(props.hmdAssignedAssessment && props.hmdAssignedAssessment.calibration){
            setCalibration({
                language: props.hmdAssignedAssessment.calibration?.language || "english",
                volume: props.hmdAssignedAssessment.calibration.volume || 1,
                calibrationCheck: props.hmdAssignedAssessment.calibration.calibrationCheck,
                patientGazeEyeCheck: props.hmdAssignedAssessment.calibration.patientGazeEyeCheck,
                positionCheck: props.hmdAssignedAssessment.calibration.positionCheck
            })
        }
    }, [props.hmdAssignedAssessment])

    const calibrationFormSubmitHandler = (event) => {
        console.log(event, props.hmdAssignedAssessment)
        const data = props.hmdAssignedAssessment;
        data.calibration = event;
        props.updateAssessment(data)
        props.setModalWithType(false);
    }

    return (
        <React.Fragment>
            <div className="modal-header">
            <h2 className="modal-title" id="patient-modalLabel">Calibrate</h2>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = { () => props.setModalWithType(false) }>
                    <span aria-hidden="true"><i className="fabicon-close"></i></span>
                </button>
            </div>
            <div className="modal-body pd-b-30">
                <Formik
                    enableReinitialize = { true }
                    initialValues = { calibration }
                    validationSchema = { AssessmentCalibrationSchema }
                    onSubmit = { calibrationFormSubmitHandler }
                >
                {
                    ({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                    <React.Fragment>
                        <form onSubmit = { handleSubmit }>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <h3 className="regular">Volume Check</h3>
                                    <Slider min={0} max={100} name="volume" onChange={(event)=>{ calibration.volume = event || 0 }} defaultValue={values.volume} />
                                    <span className="JsShowValue"></span>
                                </div>
                                
                                <div className="form-group col-md-12">
                                    <h3 className="regular">Callibration Check</h3>
                                    <Slider min={0} max={100} defaultValue={0} disabled={true} />
                                    <div className="checks__button">
                                        <input type="button" name="start" />
                                        <label>Start Check</label>
                                    </div>
                                </div>
                                
                                <div className="form-group col-md-12">
                                    <h3 className="regular">Patient Gaze Eye Tracking</h3>
                                    <Slider min={0} max={100} defaultValue={0} disabled={true} />
                                    <div className="checks__button">
                                        <input type="button" name="start" />
                                        <label>Start Check</label>
                                    </div>
                                </div>
                                
                                <div className="form-group col-md-12">
                                    <h3 className="regular">Position Check</h3>
                                    <div className="custom-check">
                                        <input type="checkbox" name="positionCheck" onChange={()=>{ calibration.positionCheck = !calibration.positionCheck }}/>
                                        <label>Check Complete</label>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-red" style={{"marginRight":"10px"}}>Start Trial</button>
                            {/* <button className="btn btn-red" style={{"marginRight":"10px"}} onClick = { () => {} }>Start Trial</button> */}
                            <button className="btn btn-red" style={{"marginRight":"10px"}} onClick = { () => props.setModalWithType(false) }>Skip Trial</button>
                            <button className="btn btn-red" style={{"marginRight":"10px"}} disabled={true} onClick = { () => {} }>Restart Trial</button>

                        </form>
                    </React.Fragment>
                    )
                }
                </Formik>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = ({ hmdDevices }) => ({
    hmdAssignedAssessment: hmdDevices.hmdAssignedAssessment || null
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalWithType,
    updateAssessment
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(CalibrateModal)
