export const EYE_COLOR_MAP = {
    // black and 7 other colors
    '-2': '#222222',
    '-1': '#222222',
    0: '#222222',
    1: '#CDCDCD',
    2: '#CDCDCD',
    3: '#CDCDCD',
    4: '#CDCDCD',
    5: '#CDCDCD',
    6: '#CDCDCD',
    7: '#D9D9D9',
    8: '#D9D9D9',
    9: '#D9D9D9',
    10: '#D9D9D9',
    11: '#D9D9D9',
    12: '#D9D9D9',
    13: '#E2E2E2',
    14: '#E2E2E2',
    15: '#E2E2E2',
    16: '#E2E2E2',
    17: '#E2E2E2',
    18: '#E2E2E2',
    19: '#ECECEC',
    20: '#ECECEC',
    21: '#ECECEC',
    22: '#ECECEC',
    23: '#ECECEC',
    24: '#ECECEC',
    25: '#F1F1F1',
    26: '#F1F1F1',
    27: '#F1F1F1',
    28: '#F1F1F1',
    29: '#F1F1F1',
    30: '#F1F1F1',
    31: '#F6F6F6',
    32: '#F6F6F6',
    33: '#F6F6F6',
    34: '#F6F6F6',
    35: '#F6F6F6',
    36: '#F6F6F6',
    37: '#FBFBFB',
    38: '#FBFBFB',
    39: '#FBFBFB',
    40: '#FBFBFB',
    41: '#FBFBFB',
    42: '#FBFBFB',
}

export const EYE_COLOR_MAP_TDP_PDP = {
    DARK: EYE_COLOR_MAP[0],
    GREY: EYE_COLOR_MAP[15],
    LIGHT_GRAY: EYE_COLOR_MAP[30],
    LIGHT: EYE_COLOR_MAP[42],
}

export const CHART_NAMES = {
    SENSITIVITY_VALUES : "SENSITIVITY_VALUES",
    SENSITIVITY_GRAYSCALE : "SENSITIVITY_GRAYSCALE",
    EVENT_ANALYSIS : "EVENT_ANALYSIS",
    TREND_ANALYSIS : "TREND_ANALYSIS",
    TOTAL_DEVIATION_VALUES: "TOTAL_DEVIATION_VALUES",
    PATTERN_DEVIATION_VALUES: "PATTERN_DEVIATION_VALUES",
    PATTERN_DEVIATION_PROBABILITIES: "PATTERN_DEVIATION_PROBABILITIES",
    TOTAL_DEVIATION_PROBABILITIES: "TOTAL_DEVIATION_PROBABILITIES",
    CLUSTER_MD: "CLUSTER_MD",
    GAZE_TRACKER: "GAZE_TRACKER"
}

export const SINGLE_VIEW_CHARTS = {
    SENSITIVITY_VALUES: 'sensitivity values',
    SENSITIVITY_GRAYSCALE: 'sensitivity grayscale',
    CRITERIA_CALCULATOR: 'criteria calculator',
    TOTAL_DEVIATION_VALUES: 'total deviation values',
    PATTERN_DEVIATION_VALUES: 'pattern deviation values',
    CLUSTER_MD: 'cluster md',
    TOTAL_DEVIATION_PROBABILITIES: 'total deviation probabilities',
    PATTERN_DEVIATION_PROBABILITIES: 'pattern deviation probabilities',
    GAZE_TRACKER: 'gaze tracker'
}

export const OVERVIEW_CHARTS = {
    SENSITIVITY_GRAYSCALE: 'sensitivity grayscale',
    SENSITIVITY_VALUES: 'sensitivity values',
    TOTAL_DEVIATION_PROBABILITIES: 'total deviation probabilities',
    PATTERN_DEVIATION_PROBABILITIES: 'pattern deviation probabilities'
}

export const PROGRESSION_CHARTS = {
    SENSITIVITY_GRAYSCALE: 'sensitivity grayscale',
    PATTERN_DEVIATION_PROBABILITIES: 'pattern deviation probabilities',
    EVENT_ANALYSIS: 'event analysis',
    TREND_ANALYSIS: 'trend analysis'
}

export const FOLLOWUP_CHARTS = {
    TOTAL_DEVIATION_VALUES: 'total deviation values',
    PATTERN_DEVIATION_VALUES: 'pattern deviation values',
    SENSITIVITY_VALUES: 'sensitivity values',
    SENSITIVITY_GRAYSCALE: 'sensitivity grayscale'
}

export const CHART_ORIENTATION = {
    OS: "OS",
    OD: "OD"
}

export const EYE_CHART_DEFAULT_FORMAT = {
    OS: [0, 1, 2, 7, 8, 9, 10, 17, 18, 45, 54, 55, 62, 63, 64, 65, 70, 71],
    OD: [0, 1, 6, 7, 8, 9, 16, 17, 26, 53, 54, 61, 62, 63, 64, 69, 70, 71]
}