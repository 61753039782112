import * as d3 from "d3"

export const eventAnalysisChart = (chartId, responseJson, chartOrientation) => {
    const ROWS = 10
    const COLS = 11

    const width = COLS*100
    const height = ROWS*100

    const rw = 99
    const rh = 99

    let div
    let svg

    const renderChartFrame = ()=> {
        let data = [];

        document.getElementById(`${chartId}`).innerHTML = ""

        div = d3.select('#'+chartId)
        svg = div.append('svg')
                .attr('viewBox', `0 0 ${width} ${height}`)
                .attr('preserveAspectRatio', 'xMidYMid meet')

        for (let k = 0; k < ROWS; k += 1) {
            data.push(d3.range(COLS));
        }

        // Create a group for each row in the data matrix and
        // translate the group vertically
        let grp = svg.selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('id', (d, i) => `row-${(i+1)}`)
            .attr('transform', (d, i) => `translate(0, ${ 100 * i })`);

        // For each group, create a set of rectangles and bind 
        // them to the inner array (the inner array is already
        // binded to the group)
        grp.selectAll('rect')
            .data((d) => d)
            .enter()
            .append('rect')
            .attr('id', (d, i) => `col${i}`)
            .attr('x', (d, i) => `${100 * i}`)
            .attr('width', rw)
            .attr('height', rh)
            .attr('style', (d, i) => {
                return 'fill:transparent;';
            });

        for(let i=1; i<=9; i++){
            svg
            .select('g#row-'+i)
            .append("image")
            .attr("x", chartOrientation === 'OS'? 500: 400).attr("y", 0)
            .attr("xlink:href", function(d, i){
                return window.location.origin + "/assets/images/chart-img/vertical-line.png";
            })
        }

        svg
            .select('g#row-3')
            .append("image")
            .attr("x", 0).attr("y", 200)
            .attr("xlink:href", function(d, i){
                return window.location.origin + "/assets/images/chart-img/horizontal-line.png";
            })
    }

    const plotThePoints = () => {

        for(var i=0; i<responseJson.length; i++){
            let { x, y, value } = responseJson[i]
            if(x>=0 && y>=0){
                plotInFirstQuadrant(x, y, value);
            } else if(x<0 && y>=0){
                plotInSecondQuadrant(x, y, value);
            } else if(x<0 && y<0){
                plotInThirdQuadrant(x, y, value);
            } else if(x>=0 && y<0){
                plotInFourthQuadrant(x, y, value);
            }
        }
    }

    const plotInFirstQuadrant = (x, y, value) => {
        x = x ? Math.abs(x) : x;
        y = y ? Math.abs(y) : y;

        var el = svg
        .selectAll('g#row-' + parseInt((ROWS/2) - y))
        .select('rect[id=col'+ parseInt(COLS/2 + x) +']')

        svg
        .select('g#row-' + parseInt((ROWS/2) - y))
        .append("image")
        .attr("x", parseInt(el.attr('x'))+36).attr("y", 36)
        .attr("width", ()=>{
            return value>=35 && value<37 ? "10": "30"
        })
        .attr("height", ()=>{
            return value>=35 && value<37 ? "10": "30"
        })
        .attr("xlink:href", getImageUrl(value))

    }

    const plotInSecondQuadrant = (x, y, value) => {
        x = x ? Math.abs(x) : x;
        y = y ? Math.abs(y) : y;

        var el = svg
        .selectAll('g#row-' + parseInt((ROWS/2) - y))
        .select('rect[id=col'+ parseInt(COLS/2 - x) +']')

        svg
        .select('g#row-' + parseInt((ROWS/2) - y))
        .append("image")
        .attr("x", parseInt(el.attr('x'))+36).attr("y", 36)
        .attr("width", ()=>{
            return value>=35 && value<37 ? "10": "30"
        })
        .attr("height", ()=>{
            return value>=35 && value<37 ? "10": "30"
        })
        .attr("xlink:href", getImageUrl(value))

    }

    const plotInThirdQuadrant = (x, y, value) => {
        x = x ? Math.abs(x) : x;
        y = y ? Math.abs(y) : y;

        var el = svg
        .selectAll('g#row-' + parseInt((ROWS/2) + y))
        .select('rect[id=col'+ parseInt(COLS/2 - x) +']')

        svg
        .select('g#row-' + parseInt((ROWS/2) + y))
        .append("image")
        .attr("x", parseInt(el.attr('x'))+36).attr("y", 36)
        .attr("width", ()=>{
            return value>=35 && value<37 ? "10": "30"
        })
        .attr("height", ()=>{
            return value>=35 && value<37 ? "10": "30"
        })
        .attr("xlink:href", getImageUrl(value))

    }

    const plotInFourthQuadrant = (x, y, value) => {
        x = x ? Math.abs(x) : x; 
        y = y ? Math.abs(y) : y; 

        let el = svg
        .selectAll('g#row-' + parseInt((ROWS/2) + y))
        .select('rect[id=col'+ parseInt(COLS/2 + x) +']')

        svg
        .select('g#row-' + parseInt((ROWS/2) + y))
        .append("image")
        .attr("x", parseInt(el.attr('x'))+36).attr("y", 36)
        .attr("width", ()=>{
            return value>=35 && value<37 ? "10": "30"
        })
        .attr("height", ()=>{
            return value>=35 && value<37 ? "10": "30"
        })
        .attr("xlink:href", getImageUrl(value))

    }

    const getImageUrl = (value)=>{
        let imageUrl = window.location.origin

        if(value>=29 && value<33){
            imageUrl += "/assets/images/chart-img/blank-triangle.png"
        } else if(value>=33 && value<35){
            imageUrl += "/assets/images/chart-img/red-triangle.png"
        } else if(value>=35 && value<37){
            imageUrl += "/assets/images/chart-img/circle.png"
        } else {
            imageUrl += "/assets/images/chart-img/red-triangle.png"
        }

        return imageUrl
    }

    renderChartFrame()
    plotThePoints()

}
