import CONFIG from "../../config"
import { addActiveAssessment, setActiveAssessmentProcessedData } from "../../actions/assessment.action"
import * as Logger from "../../shared/utils/logger";

export const onProcessedDataUpdate = (data) => {
    Logger.message("PROCESSED DATA STREAM RECEIVING DATA...", { data })
    let { data: { raw: processed } } = data

    CONFIG.Store.dispatch(addActiveAssessment(data))
    CONFIG.Store.dispatch(setActiveAssessmentProcessedData(processed))
}