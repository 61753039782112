import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { setModalWithType } from '../../../actions/modal.action.js';
import {
  getWifiNetworksList,
  getWifiStatus,
  getWifiConnectionStatus,
  connectToWifi,
  forgetWifiConnection,
  manageWifiStatus,
} from '../../../actions/wifiBluetoothConnection.action.js';
import CustomTooltip from '../tooltip.js';
import { TOOLTIP_TEXTS } from '../../../shared/constants/shared.constants.js';

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  ssid: Yup.string('WiFi network is required').nullable().default('').required('WiFi network is required'),
});

export const WifiConnectionModal = props => {

  const [initValue, setInitValue] = useState({ ssid: '', connectedSSID: '' });
  const [wifiEnabled, setWifiEnabled] = useState(props.wifiStatus);
  const [wifiConnected, setWifiConnected] = useState(props.isWifiConnected);

  const cancelClickHandler = () => {
    props.setModalWithType(false);
  };

  const fetchNetworkData = async () => {
    await props.getWifiNetworksList();
    await props.getWifiStatus();
    await props.getWifiConnectionStatus();
  };

  // useEffect(() => {
  //   fetchNetworkData();

  //   const interval = setInterval(fetchNetworkData, 10000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [props.getWifiNetworksList, props.getWifiStatus, props.getWifiConnectionStatus]);

  useEffect(() => {
    setInitValue({ ssid: props.connectedSSID });
  }, [props.connectedSSID]);

  useEffect(() => {
    setWifiConnected(props.isWifiConnected);
    setWifiEnabled(props.wifiStatus);
  }, [props.isWifiConnected, props.wifiStatus]);

  const handleWifiToggle = async () => {
    if (wifiEnabled) {
      await props.manageWifiStatus(false);
    } else {
      await props.manageWifiStatus(true);
    }
    setWifiEnabled(prev => !prev);
    await fetchNetworkData();
  };

  const handleForgetConnection = async values => {
    await props.forgetWifiConnection(values);
    await props.fetchNetworkData();
  };

  const handleDisconnect = async values => {
    await props.connectToWifi({
      connect: false,
      ...values,
    });
    await fetchNetworkData();
  };

  const handleConnectionToggle = async () => {};

  const onSubmit = async (values, { setSubmitting }) => {
    await props.connectToWifi({
      connect: true,
      ...values,
    });
    await fetchNetworkData();
    setSubmitting(false);
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">
          Wifi Connector
        </h2>
        <div>
          <button
            type="button"
            className="close focus-none"
            data-dismiss="modal"
            aria-label="Close"
            onClick={cancelClickHandler}
          >
            <CustomTooltip text={TOOLTIP_TEXTS.REFRESH}>
              <span aria-hidden="true">
                <i className="fabicon-close"></i>
              </span>
            </CustomTooltip>
          </button>
          <button
            type="button"
            className="close focus-none"
            // data-dismiss="modal"
            aria-label="Refresh"
            onClick={fetchNetworkData}
          >
            <span aria-hidden="true">
              <i className="fabicon-refresh"></i>
            </span>
          </button>
        </div>
      </div>

      <div className="modal-body">
        <div className="mt-3 test__content--scroll mcustomscrollbar">
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active">
              <Formik
                initialValues={initValue}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, handleSubmit, handleChange, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <div className="d-flex justify-content-start">
                        <label className="w-25">WiFi:</label>
                        <div className="onoffswitch">
                          <input
                            type="checkbox"
                            name="wifiToggle"
                            className="onoffswitch-checkbox"
                            id="wifiToggle"
                            tabIndex="0"
                            checked={wifiEnabled}
                            onChange={() => handleWifiToggle(values)}
                          />
                          <label
                            className="onoffswitch-label"
                            htmlFor="wifiToggle"
                          >
                            <span className="onoffswitch-inner content-wifi"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-start">
                        <label className="w-25">Connected:</label>
                        <label>
                          {props.isWifiConnected ? 'Yes' : 'No'}
                        </label>
                      </div>
                      <label htmlFor="ssid">Available WiFi Networks:</label>
                      <select
                        id="ssid"
                        name="ssid"
                        className={`form-control ${
                          errors.ssid && touched.ssid ? 'is-invalid' : ''
                        }`}
                        value={values.ssid}
                        defaultValue={values.ssid}
                        onChange={handleChange}
                      >
                        <option value="" label="Select WiFi network" />
                        {props.wifiNetworks.map((network, index) => (
                          <option
                            key={index}
                            value={network}
                            selected={network === values.ssid}
                          >
                            {network}
                          </option>
                        ))}
                      </select>
                      {errors.ssid && touched.ssid && (
                        <div className="invalid-feedback">{errors.ssid}</div>
                      )}
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      {props.connectedSSID &&
                      values.ssid === props.connectedSSID ? (
                        <button
                          type="button"
                          className="btn btn-red"
                          onClick={() => handleDisconnect(values)}
                        >
                          Disconnect
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-red">
                          Connect
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-red"
                        onClick={() => handleForgetConnection(values)}
                      >
                        Forget Connection
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalWithType,
      getWifiNetworksList,
      getWifiStatus,
      getWifiConnectionStatus,
      connectToWifi,
      forgetWifiConnection,
      manageWifiStatus,
    },
    dispatch,
  );

const mapStateToProps = ({ networks }) => ({
  wifiNetworks: networks.wifiList,
  wifiStatus: networks.wifiStatus,
  isWifiConnected: networks.isWifiConnected,
  connectedSSID: networks.connectedSSID,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WifiConnectionModal);
