import React, { useEffect } from "react"
import { connect } from 'react-redux'

export const Loader = (props) => {

  useEffect(() => {
    props.setLoader ?  document.body.classList.add("overflow-hidden") : document.body.classList.remove("overflow-hidden")
  }, [props.setLoader])

  return (
    <React.Fragment>
      {
        props.setLoader ? (
          <div className = { `eadie-pageloader` }>
            <div className = "loader-inner">
                <img src="/assets/images/loader.gif" alt="loading..." />
            </div>
        </div>
        ) : null
      }
    </React.Fragment>
  )
}

const mapStateToProps = ({ loader }) => ({
  setLoader: loader.set
})

export default connect(mapStateToProps)(Loader)
