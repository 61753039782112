import { MODALS } from "../shared/constants/actions.constants"

const initialState = {
  set: false,
  type: "",
  data: null
}

export default function(state = initialState, action) {
  switch (action.type) {
      
    case MODALS.SET_MODAL:
        let { flag, type, data } = action.payload
        return {
            ...state,
            set: flag,
            type,
            data
        }

    default:
      return state
  }
}
