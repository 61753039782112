import { default as React, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import './responsive-video-player.css';
import CONFIG from '../../config';

const EyeCaptureWebcam = props => {

  return (
    <Col className="col-md-6 d-flex justify-content-center align-items-start equal-height-col bg-image camera-feed" 
    style={{backgroundImage: `url(${CONFIG.CONSTANTS.VIDEO_URL})`, backgroundOrigin: 'border-box'}}
    ></Col>
    // <Col className="col-md-6 d-flex justify-content-center align-items-center">
    //   <img
    //     src="http://127.0.0.1:5000/video_feed/machineid/analyse"
    //     alt="eye plotting"
    //     width={'50%'}
    //   />
    // </Col>
  );
};

export default EyeCaptureWebcam;
