
import Dygraph from 'dygraphs'

export const gazeTrackerChart = (chartId, responseJson, chartOrientation) => {

    let el = null;
    let data = [];
    let div = document.getElementById(chartId)

    if(!el){
        el = new Dygraph(div, data,
        {
            drawPoints: true,
            showRoller: false,
            valueRange: [-2, 42],
            labels: ['Position', 'Value'],
            animatedZooms: true,
            title: " "
        });
    }

    if(responseJson){
        data = [];
        el.updateOptions( { 'file': data } );

        for(let i=0; i<responseJson.length; i++){
            data.push([i+1, responseJson[i].value]);
            el.updateOptions( { 'file': data } );
        }
    }

}
