import * as Yup from 'yup'
import { LANGUAGES } from './assessment.model';

export const AssessmentCalibrationForm = {
  language: "english",
  volume: 0,
  calibrationCheck: false,
  patientGazeEyeCheck: false,
  positionCheck: false
}

export const AssessmentCalibrationSchema = Yup.object().shape({
  language: Yup.string()
    .required('Language is required')
    .oneOf(LANGUAGES),
  volume: Yup.number()
    .required('Volume is required'),
  calibrationCheck: Yup.boolean()
    .required('Calibration check is required'),
  patientGazeEyeCheck: Yup.boolean()
    .required('Patient gaze eye check is required'),
  positionCheck: Yup.boolean()
    .required('Position check is required')
})
