import { LOADER } from "../shared/constants/actions.constants"

const initialState = {
  set: false
}

export default function(state = initialState, action) {
  switch (action.type) {
      
    case LOADER.SET_LOADER:
      return {
        ...state,
        set: action.payload
      }

    default:
      return state
  }
}
