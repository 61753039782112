import { setModalWithType } from '../../actions/modal.action';
import CONFIG from '../../config';
import * as FlashMessage from '../../shared/utils/flashMessage';
import { MODALS } from '../../shared/constants/actions.constants';
import { SUCCESS } from '../../shared/constants/messages.constants';
import { addActiveAssessment } from '../../actions/assessment.action';

export const onBlindSpotRepeatFailure = (assessment = {}) => {
  CONFIG.Store.dispatch(
    setModalWithType(true, MODALS.BLIND_SPOT_MODAL, {
      backdrop: 'static',
    }),
  );
};

export const onBlindSpotRepeat = (data = {}) => {
  FlashMessage.success(SUCCESS.ASSESSMENT_SUCCESSFULLY_UPDATED);
  if (CONFIG.Store.getState()?.modals?.type === MODALS.BLIND_SPOT_MODAL) {
    CONFIG.Store.dispatch(setModalWithType(false));
  }
  CONFIG.Store.dispatch(addActiveAssessment(data));
};
