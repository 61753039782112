import { default as React, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { CHART_NAMES, CHART_ORIENTATION } from '../../shared/constants/chart.constants';
import Eye from '../shared/graphs/eye';
import './responsive-video-player.css';

const EyePlotting = (props) => {

  return (
    <Col className="col-md-6 equal-height-col" style={{ paddingRight: '100px' }}>
      {
        props.assessment.onGoingTestType === 'right' && (
          <Eye
            chartId={`chart-OD-livechart-1`}
            assessment={props.assessment}
            chartName={CHART_NAMES.SENSITIVITY_VALUES}
            chartData={props?.processedData?.OD || []}
            chartOrientation={CHART_ORIENTATION.OD}
            position='static'
            transform={props.assessment.configuration.grid ==='10-2'? 0.15: 0.06}
            height={350}
            width={250}
            top={-80}
            right={-80}
          />
        )}
      {
        props.assessment.onGoingTestType === 'left' && (
          <Eye
            chartId={`chart-OS-livechart-1`}
            assessment={props.assessment}
            chartName={CHART_NAMES.SENSITIVITY_VALUES}
            chartData={props?.processedData?.OS || []}
            chartOrientation={CHART_ORIENTATION.OS}
            position='static'
            transform={props.assessment.configuration.grid ==='10-2'? 0.15: 0.06}
            height={350}
            width={250}
            top={-80}
            right={-80}
          />
        )}
    </Col>
  );
};

export default EyePlotting;
