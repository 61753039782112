import * as Yup from 'yup'

export const GRID_TYPES = [
  '24-2C',
  '24-2',
  '10-2',
]

export const RESPONSE_TYPES = [
  'clicker',
  'seccade central'
]

export const STRATEGIES = [
  'zest',
  'full threshold'
]

export const EYE = [
  'left',
  'right',
  'binoculor',
  'left then right',
  'right then left'
]

export const EYE_ENUM = {
  LEFT: 'left',
  RIGHT: 'right',
  BINOCULOR: 'binoculor',
  LEFT_THEN_RIGHT: 'left then right',
  RIGHT_THEN_LEFT: 'right then left'
}

export const TEST_OTHER_EYE_OPTIONS = [
  'left then right',
  'right then left'
]

export const COLOR = [
  'white on white'
]

export const TEST_FOVEAL_THRESHOLD = [
  'yes',
  'no'
]

export const STIMULUS_SIZE = [
  'III',
  'V'
]

export const LANGUAGES = [
  'english',
  'french'
]

export const AssessmentConfigurationForm = {
  patientId: "",
  hmdId: "",
  configuration: {
    grid: "",
    responseType: "",
    strategy: "zest",
    eye: "",
    color: "",
    testFovealThreshold: "",
    patientParameters: "",
    osSphere: "",
    odSphere: "",
    osCylinder: "",
    odCylinder: "",
    osAxis: "",
    odAxis: "",
    stimulusSize: "III",
    default: false
  }
}

export const AssessmentConfigurationSchema = () => {
  let assessmentConfigurationSchema = {
    patientId: Yup.string().required('Patient Id is required'),
    hmdId: Yup.string().required('HMD Id is required')
  }
  let configurationSchema = {
    grid: Yup.string()
      .required('Grid is required')
      .oneOf(GRID_TYPES),
    responseType: Yup.string()
      .required('Response type is required')
      .oneOf(RESPONSE_TYPES),
    strategy: Yup.string()
      .required('Strategy is required')
      .oneOf(STRATEGIES),
    eye: Yup.string()
      .required('Eye is required')
      .oneOf(EYE),
    color: Yup.string()
      .required('Color is required')
      .oneOf(COLOR),
    testFovealThreshold: Yup.string()
      .required('Test foveal threshold is required')
      .oneOf(TEST_FOVEAL_THRESHOLD),
    patientParameters: Yup.number()
      .required('Patient parameters is required'),
    osSphere: Yup.number()
      .required('Required'),
    odSphere: Yup.number()
      .required('Required'),
    osCylinder: Yup.number()
      .required('Required'),
    odCylinder: Yup.number()
      .required('Required'),
    osAxis: Yup.number()
      .required('Required'),
    odAxis: Yup.number()
      .required('Required'),
    stimulusSize: Yup.string()
      .required('Stimulus size is required')
      .oneOf(STIMULUS_SIZE),
    default: Yup.boolean()
      .required('Default is required')
  }
  
  assessmentConfigurationSchema.configuration = Yup.object(configurationSchema)
  return Yup.object().shape(assessmentConfigurationSchema)
}

export const AssessmentCalibrationForm = {
  calibration: {
    language: "",
    volume: 0,
    calibrationCheck: false,
    patientGazeEyeCheck: false,
    positionCheck: false
  }
}

export const AssessmentCalibrationSchema = () => {
  let assessmentCalibrationSchema = {}

  let calibrationSchema = {
    language: Yup.string()
      .required('Language is required')
      .oneOf(LANGUAGES),
    volume: Yup.number()
      .required('Volume is required'),
    calibrationCheck: Yup.boolean()
      .required('Calibration check is required'),
    patientGazeEyeCheck: Yup.boolean()
      .required('Patient gaze eye check is required'),
    positionCheck: Yup.boolean()
      .required('Position check is required')
  }

  assessmentCalibrationSchema.calibration = Yup.object(calibrationSchema)
  return Yup.object().shape(assessmentCalibrationSchema)
}
