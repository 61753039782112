export const getChartScaleSize = (grid, setModal) => {
  if (grid === '10-2' && setModal === true) {
    return 0.2;
  } else if (grid === '10-2' && setModal === false) {
    return 0.08;
  } else if (grid === '24-2' && setModal === true) {
    return 0.08;
  } else if (grid === '24-2' && setModal === false) {
    return 0.08 / 2.5;
  } else if (grid === '24-2C' && setModal === true) {
    return 0.08;
  } else if (grid === '24-2C' && setModal === false) {
    return 0.08 / 2.5;
  } else if (grid === '30-2' && setModal === true) {
    return 0.08;
  } else if (grid === '30-2' && setModal === false) {
    return 0.08 / 2.5;
  }
};
