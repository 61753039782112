import React, { useCallback, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import EyeCaptureWebcam from './eyeCaptureWebcam';
import EyePlotting from './eyePlotting';
import './responsive-video-player.css';
import CONFIG from '../../config';

const ResponsivePlayer = props => {
  const [leftEyeDevice, setLeftDevice] = React.useState([]);
  const [rightEyeDevice, setRightDevice] = React.useState([]);
  const [imgUrl, setImgUrl] = React.useState('');

  const leftRight = {
    left: 'LEFT', //USB Camera-LEFT
    right: 'RIGHT', //USB Camera-RIGHT
  };

  const handleDevices = useCallback(
    mediaDevices => {
      let left = mediaDevices.find(
        ({ kind, deviceId, label }) =>
          kind === 'videoinput' && label.includes(leftRight.left),
      );
      let right = mediaDevices.find(
        ({ kind, deviceId, label }) =>
          kind === 'videoinput' && label.includes(leftRight.right),
      );

      console.table(mediaDevices);

      setLeftDevice(left);
      setRightDevice(right);
    },
    [setLeftDevice, props.processedData, props.assessment],
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices, props.assessment]);

  const rowRef = useRef(null);

  // useEffect(()=>{
  //   (async function () {
  //     try {
  //         const res = await fetch(CONFIG.CONSTANTS.VIDEO_URL)
  //         // console.log(res.data)
  //         if (res.ok) {
  //             setImgUrl(res.url)
  //         } 
  //         console.log(res);
  //     } catch (error) {
  //         console.error(error)
  //     }
  //   })()
  // },[])

  return (
    <div className='position-relative'>
      <Row className='equal-height-row p-2'>
        {props.assessment && props.assessment.onGoingTestType === 'left' && (
          <React.Fragment>
            <EyeCaptureWebcam rowRef={rowRef} device={leftEyeDevice} />
            <EyePlotting assessment={props.assessment} processedData={props.processedData}/>
          </React.Fragment>
        )}

        {props.assessment && props.assessment.onGoingTestType === 'right' && (
          <React.Fragment>
            <EyePlotting assessment={props.assessment} processedData={props.processedData}/>
            <EyeCaptureWebcam rowRef={rowRef} device={rightEyeDevice} />
          </React.Fragment>
        )}

        {props.assessment === null && (
          <Col className="col-md-12 d-flex justify-content-center align-items-center camera-feed"  style={{minHeight: '50vh',backgroundImage: `url(${CONFIG.CONSTANTS.VIDEO_URL})`}}>
          </Col>
        )}
      </Row>
      
      {/* <Row style={{ textAlign: 'center' }}>
        <Col>
          <label>
            {props.assessment &&
              props.assessment.onGoingTestType === 'left' &&
              'Left'}
          </label>
        </Col>
        <Col>
          <label>
            {props.assessment &&
              props.assessment.onGoingTestType === 'right' &&
              'Right'}
          </label>
        </Col>
      </Row> */}
    </div>
  );
};

const mapStateToProps = ({ assessment }) => ({
  assessment: assessment.activeAssessment,
  processedData: assessment.activeAssessmentProcessedData,
});

export default connect(mapStateToProps)(ResponsivePlayer);
