import React from 'react';
import LoginPanel from '../components/login/loginPanel';
import LoginHeader from '../components/shared/loginHeader';

const LoginContainer = props => {
  return (
    <>
      <LoginHeader />
      <LoginPanel />
    </>
  );
};

export default LoginContainer;
