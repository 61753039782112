import { MODALS } from "../shared/constants/actions.constants"

/**
 * @desc Action to set modal state with type
 * @param {boolean} flag
 * @param {string} type
 * @public
 */
export const setModalWithType = (flag = false, type = "", data = null) => {
    return {
      type: MODALS.SET_MODAL,
      payload: { flag, type, data }
    }
}