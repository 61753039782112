import React from "react"
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const CustomTooltip = (props) => {
    return (
        <OverlayTrigger
            placement = "top"
            delay = { { show: 250, hide: 400 } }
            overlay = { (
                <Tooltip id="button-tooltip" { ...props }>
                    { props.text }
                </Tooltip>
            ) }
        >
            { props.children }
        </OverlayTrigger>
    )
}

export default CustomTooltip