import React, { useState } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { Provider } from "react-redux"
import ModalContainer from './containers/modalContainer'
import Loader from "./components/shared/loader"
import CONFIG from "./config"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import "react-datepicker/dist/react-datepicker.css"
import AppRouter from "./appRouter"
import 'bootstrap-daterangepicker/daterangepicker.css'
import packageJson from '../package.json';
import releaseNotesJson from './release-notes.json';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const App = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  
  toast.configure({
    autoClose: 2000,
    draggable: false,
  })

  return (
    <Provider store = { CONFIG.Store }>
      <Router>
        <div className="App">
          <AppRouter />
          <ToastContainer />
        </div>
      </Router>
      <ModalContainer />
      <Loader />
      <pre className="app_version" onClick={onOpenModal}>{packageJson.version}</pre>
      <Modal open={open} onClose={onCloseModal} center>
        {
          Object.keys(releaseNotesJson).map((e) => {
            return (
              <>
                <h2>{e}</h2>
                <ul>{ Object.keys(releaseNotesJson[e]).slice(0,5).map((p) => <li>{p}. {releaseNotesJson[e][p]}</li>)}</ul>
              </>
            )
          })
        }
      </Modal>
    </Provider>
  )
}

export default App
