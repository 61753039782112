import React, { useEffect } from 'react';
import { clearAuthToken } from '../../services/localstorage/localStorage.service';
import { ROUTES } from '../../shared/constants/routes.constants';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setModalWithType } from '../../actions/modal.action';
import { MODALS } from '../../shared/constants/actions.constants';
import {
  connectToWifi,
  getBluetoothConnectionStatus,
  getBluetoothNetworksList,
  getBluetoothStatus,
  getWifiConnectionStatus,
  getWifiNetworksList,
  getWifiStatus,
} from '../../actions/wifiBluetoothConnection.action';

export const LoginHeader = props => {

  const onWifiManagement = async event => {
    props.setModalWithType(true, MODALS.WIFI_CONNECTION_MODAL, {
      size: 'md',
      backdrop: 'static',
    });
  };

  const onBluetoothManagement = async event => {
    props.setModalWithType(true, MODALS.BLUETOOTH_CONNECTION_MODAL, {
      size: 'md',
      backdrop: 'static',
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await props.getWifiNetworksList();
      await props.getWifiStatus();
      await props.getWifiConnectionStatus();
      await props.getBluetoothNetworksList();
      await props.getBluetoothStatus();
      await props.getBluetoothConnectionStatus();

    };

    fetchData();

    const interval = setInterval(fetchData, 20000);

    return () => {
      clearInterval(interval);
    };
  }, [props.getWifiNetworksList, props.getWifiStatus, props.getWifiConnectionStatus]);

  return (
    <header className="header d-flex">
      <div className="header-logo d-flex justify-content-center">
        <img
          className="header-logo-image"
          src="/assets/images/logowhite.png"
          alt="Eadietech Logo"
        />
      </div>
      <div className="header-nav d-flex justify-content-between w-100">
        <div className="header-nav-left"></div>
        <div className="header-nav-right">
          <ul>
            <li className="nav-item dropdown">
              <a
                className="header-nav-avatar ml-1 mr-1"
                href="#!"
                onClick={onBluetoothManagement}
              >
                <img
                  className="rounded-circle"
                  src={`/assets/images/bt-${
                    props.isBluetoothConnected ? '' : 'dis'
                  }connected.png`}
                  alt="bluetooth"
                />
              </a>
              <a
                className="header-nav-avatar ml-1 mr-1"
                // id="profileDropdown"
                // data-toggle="dropdown"
                href="#!"
                onClick={onWifiManagement}
              >
                <img
                  className="rounded-circle"
                  src={`/assets/images/wifi-${
                    props.isWifiConnected ? '' : 'dis'
                  }connected.png`}
                  alt="wifi"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalWithType,
      getWifiNetworksList,
      getWifiStatus,
      getWifiConnectionStatus,
      connectToWifi,
      getBluetoothNetworksList,
      getBluetoothStatus,
      getBluetoothConnectionStatus
    },
    dispatch,
  );

const mapStateToProps = ({ networks }) => ({
  wifiNetworks: networks.wifiList,
  wifiStatus: networks.wifiStatus,
  isWifiConnected: networks.isWifiConnected,
  isBluetoothConnected: networks.isBluetoothConnected,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withNamespaces()(LoginHeader)));
// export default Header;
