import * as LocalStorageService from "../services/localstorage/localStorage.service"
import { AUTHTYPE, LOADER } from "../shared/constants/actions.constants"
import { ROUTES } from "../shared/constants/routes.constants"
import { SHARED } from "../shared/constants"
import { homePageUrl } from "../shared/utils/util"
import * as LoginService from "../services/login/login.service"
import * as FlashMessage from "../shared/utils/flashMessage"
import { ERROR } from '../shared/constants/messages.constants'


/**
 * @desc To make Login API call and authenticate user credentials
 * @param {object} userData // email and password properties
 * @param {object} history
 * @public
 */
export const loginUser = (userData, history) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: { user, authToken, refreshToken } } = await LoginService.authenticateUser(userData)

      onSuccessfulLogin(dispatch, history, user, authToken, refreshToken)
    } catch (error) {
      onLoginError(dispatch, error)
    }
  }
}

/**
 * @desc To fetch details of user whose token is present in local storage (if present)
 * @public
 */
export const fetchMyDetails = () => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let user = await LoginService.fetchMyDetails()

      onFetchUserDetailSuccess(dispatch, user)
    } catch (error) {
      onFetchUserDetailError(dispatch, error)
    }
  }
}

/**
 * @desc Action to set logged in user details
 * @param {object} user // User Object
 * @public
 */
export const setCurrentUser = user => {
  return {
    type: AUTHTYPE.SET_CURRENT_USER,
    payload: user
  }
}

/**
 * @desc Action to set flag is logged in user is admin or not
 * @param {object} user // User Object
 * @public
 */
export const setAdminCheck = user => {
  let res = (user && user.role === SHARED.ROLES_ENUM.ADMIN) ? true : false

  return {
    type: AUTHTYPE.SET_IS_ADMIN,
    payload: res
  }
}

/**
 * @desc To make store and local storage changes after successfull login
 * @param {object} dispatch
 * @param {object} history
 * @param {object} user // User Object
 * @param {string} authToken
 * @private
 */
const onSuccessfulLogin = (dispatch, history, user, authToken, refreshToken) => {
  LocalStorageService.setAuthToken(authToken)
  LocalStorageService.setAuthRefreshToken(refreshToken)
  dispatch(setCurrentUser(user))
  dispatch(setAdminCheck(user))
  dispatch(setLoader(false))
  history.push(homePageUrl(user))
}

/**
 * @desc To notify user on login Error
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onLoginError = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.LOGGED_IN_ERROR)
}

/**
 * @desc Action to set loader on the basis of flag
 * @param {boolean} flag
 * @private
 */
const setLoader = (flag) => {
  return { 
    type: LOADER.SET_LOADER, 
    payload: flag
  }
}

/**
 * @desc To make store changes after successfully fetching user details
 * @param {object} dispatch
 * @param {object} user // User Object
 * @private
 */
const onFetchUserDetailSuccess = (dispatch, user = {}) => {
  dispatch(setCurrentUser(user.data))
  dispatch(setAdminCheck(user.data))
  dispatch(setLoader(false))
}

/**
 * @desc log out and redirect to login screen if error in fetching auth token user details
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onFetchUserDetailError = (dispatch, error) => {
  LocalStorageService.clearAuthToken()
  window.location.href = ROUTES.LOGIN
  dispatch(setLoader(false))
}
