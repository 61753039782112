import * as Logger from "../../shared/utils/logger";

export const onProcessedDataDisconnect = (reason) => {
    // Reasons
    // 1. "transport close"         -> When I abruptly off my node server
    // 2. "io client disconnect"    -> When I close the socker connection from UI using socker.close()
    // 3. "io server disconnect"    -> When Node Server closes the socker connection using socker.close()
    
    Logger.message("PROCESSED DATA STREAM DISCONNECTED...REASON: ", { reason })
}
