import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { setModalWithType } from "../actions/modal.action"
import CalibrateModal from "../components/shared/modals/calibrateModal"
import SelectHmdModal from "../components/shared/modals/selectHmdModal"
import BlindSpotModal from '../components/shared/modals/blindSpotModal'
import Wifi_BluetoothConnectionModal from '../components/shared/modals/wifi_BluetoothConnectionModal'
import WifiConnectionModal from '../components/shared/modals/wifiConnectionModal'
import BluetoothConnectionModal from '../components/shared/modals/bluetoothConnectionModal'
import { MODALS } from "../shared/constants/actions.constants"

const ModalContainer = (props) => {

  const onModalClose = () => {
    props.setModalWithType(false, "", null)
  }

  return (
    <Modal size={ props.modalData && props.modalData.size ? props.modalData.size : '' } show = { props.setModal && props.modalType && true } backdrop={props.modalData && props.modalData.backdrop ? props.modalData.backdrop : true} onHide = { onModalClose }>
      {
        props.modalType === MODALS.CALIBRATE_MODAL ? <CalibrateModal data={props.modalData}/> : (
          props.modalType === MODALS.SELECT_HMD_MODAL ? <SelectHmdModal data={props.modalData}/> : 
            props.modalType === MODALS.WIFI_CONNECTION_MODAL ? <WifiConnectionModal data={props.modalData}/> : 
              props.modalType === MODALS.BLUETOOTH_CONNECTION_MODAL ? <BluetoothConnectionModal data={props.modalData}/> : 
                props.modalType === MODALS.BLIND_SPOT_MODAL ? <BlindSpotModal modalData = { props.modalData || {} } modalType = { props.modalType } /> : null
        )
      }
    </Modal>
  )
}


const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalWithType
  }, dispatch)
)

const mapStateToProps = ({ modals }) => ({
  setModal: modals.set,
  modalType: modals.type,
  modalData: modals.data
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
