import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setModalWithType } from '../../../actions/modal.action';
import { selectHmdDevice } from '../../../actions/hmdDevices.action';
import {
  AddHmdForm,
  AddHmdSchema,
  SelectHmdForm,
  SelectHmdSchema,
} from '../../../models/select-hmd.model';
import { Formik } from 'formik';
import { addHmdDevice } from '../../../actions/hmdDevices.action';

export const SelectHmdModal = props => {
  const [hmdForm, setHmdForm] = useState(SelectHmdForm);
  const [addHmdForm, setAddHmdForm] = useState(AddHmdForm);
  const [isAddHmdOpen, setIsAddHmdOpen] = useState(false);

  const selectHmdFormSubmitHandler = device => {
    props.selectHmdDevice(device.hmd);
    props.setModalWithType(false);
    setIsAddHmdOpen(false)
  };

  const addHmdFormSubmitHandler = values => {
    props.addHmdDevice(values);
    setIsAddHmdOpen(false)
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">
          Select HMD
        </h2>
        {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick = { () => props.setModalWithType(false) }>
                    <span aria-hidden="true"><i className="fabicon-close"></i></span>
                </button> */}
      </div>
      {isAddHmdOpen ? (
        <div className="modal-body pd-b-30">
          <Formik
            enableReinitialize={true}
            initialValues={addHmdForm}
            validationSchema={AddHmdSchema}
            onSubmit={addHmdFormSubmitHandler}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
            }) => (
              <React.Fragment>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <div className="d-flex justify-content-between">
                        <h3 className="regular">HMD</h3>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => setIsAddHmdOpen(false)}
                        >
                          <span aria-hidden="true">
                            <i className="fabicon-close"></i>
                          </span>
                        </button>
                      </div>
                      <div className="patient__flex mg-b-10">
                        <small>Device Name</small>
                        <div className="input-group patient__flex--row">
                          <input
                            type="text"
                            className={`form-control width__34 ${
                              errors.name ? 'is-invalid' : ''
                            }`}
                            id="name"
                            name="name"
                            placeholder="Device Name"
                            onChange={handleChange}
                            value={values.name}
                          />
                        </div>
                      </div>

                      <div className="patient__flex mg-b-10">
                        <small>Device Id</small>
                        <div className="input-group patient__flex--row">
                          <input
                            type="text"
                            className={`form-control width__34 ${
                              errors.deviceId ? 'is-invalid' : ''
                            }`}
                            id="deviceId"
                            name="deviceId"
                            placeholder="Device Id"
                            onChange={handleChange}
                            value={values.deviceId}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <button
                    type="submit"
                    className="btn btn-red"
                    style={{ marginRight: '10px' }}
                  >
                    Add new Device
                  </button>
                </form>
              </React.Fragment>
            )}
          </Formik>
        </div>
      ) : (
        <div className="modal-body pd-b-30">
          <Formik
            enableReinitialize={true}
            initialValues={hmdForm}
            validationSchema={SelectHmdSchema}
            onSubmit={selectHmdFormSubmitHandler}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
            }) => (
              <React.Fragment>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <div className="d-flex justify-content-between">
                        <h3 className="regular">HMD</h3>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => setIsAddHmdOpen(true)}
                        >
                          <span aria-hidden="true">
                            <i className="fabicon-CirclePlus"></i>
                          </span>
                        </button>
                      </div>

                      <select
                        className={`form-control mt-2 ${
                          errors.hmdForm &&
                          touched.hmdForm &&
                          errors.hmdForm.hmd &&
                          touched.hmdForm.hmd
                            ? 'is-invalid'
                            : null
                        }`}
                        id="hmd"
                        name="hmd"
                        onChange={handleChange}
                        value={values.hmd}
                      >
                        <option defaultValue value="">
                          Select
                        </option>
                        {props.hmdDeviceList.map((hmd, i) => (
                          <option name={hmd.id} value={hmd.id} key={i}>
                            {hmd.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-red"
                    style={{ marginRight: '10px' }}
                  >
                    Ok
                  </button>
                </form>
              </React.Fragment>
            )}
          </Formik>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ hmdDevices }) => ({
  hmdDeviceList: hmdDevices.hmdDeviceList || [],
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalWithType,
      selectHmdDevice,
      addHmdDevice
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SelectHmdModal);
