import { HOMEPAGE_ROUTES, ROUTES } from "../constants/routes.constants"

export const homePageUrl = (user) => {
    if (user && user.settings && user.settings.homePage) {
      let homePage = user.settings.homePage
      homePage = `/${homePage}`

      return HOMEPAGE_ROUTES.includes(homePage) ? homePage : ROUTES.HOME
    } else {
      return ROUTES.HOME
    }
}
