import React from "react"
import HomePanel from "../components/home/homePanel"
import { connect } from 'react-redux'
import Header from "../components/shared/header"

const HomeContainer = (props) => {
    return (
        <>
            <Header loggedInUser = { props.loggedInUser } />
            <HomePanel />
        </>
    )
}

const mapStateToProps = ({ auth }) => ({
    loggedInUser: auth.loggedInUser
})
  
export default connect(mapStateToProps)(HomeContainer)
