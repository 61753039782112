import Axios from "axios"
import { API_CONSTANTS } from "../../shared/constants/api.constants"

/**
 * @desc To create a new assessment
 * @param {object} assessmentObj
 * @public
 */
export const createAssessment = (assessmentObj) => {
    return new Promise(async (res, rej) => {
        if(assessmentObj.configuration.hasOwnProperty('os')) delete assessmentObj.configuration.os;
        if(assessmentObj.configuration.hasOwnProperty('od')) delete assessmentObj.configuration.od;
        try {
            let { data } = await Axios.post(API_CONSTANTS.ASSESSMENT.CREATE_ASSESSMENT, assessmentObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To update an assessment
 * @param {object} assessmentObj
 * @public
 */
export const updateAssessment = (assessmentObj) => {
    return new Promise(async (res, rej) => {
        if(assessmentObj.configuration.hasOwnProperty('os')) delete assessmentObj.configuration.os;
        if(assessmentObj.configuration.hasOwnProperty('od')) delete assessmentObj.configuration.od;
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.UPDATE_ASSESSMENT(assessmentObj.id), assessmentObj)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}


/**
 * @desc To update an assessment
 * @param {object} assessmentObj
 * @public
 */
export const updateAssessmentStatus = (assessmentObj) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.UPDATE_ASSESSMENT_STATUS(assessmentObj.id), {status: assessmentObj.status})
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To start an assessment
 * @param {string} assessmentId
 * @public
 */
export const startAssessment = ({ assessmentId }) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.START_ASSESSMENT(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To get assessment by assessmentId
 * @param {string} assessmentId
 * @public
 */
export const getAssessmentById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.ASSESSMENT.GET_ASSESSMENT_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To get assessments by patientId
 * @param {string} patientId
 * @public
 */
export const getAssessmentsByPatientId = (patientId, limit, sort) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await ((limit || sort)? Axios.get(API_CONSTANTS.ASSESSMENT.GET_ASSESSMENTS_BY_PATIENT_ID_AND_LIMIT_AND_SORT(patientId, limit, sort)) : Axios.get(API_CONSTANTS.ASSESSMENT.GET_ASSESSMENTS_BY_PATIENT_ID(patientId)))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
* @desc To cancel assessment by assessmentId
* @param {string} assessmentId
* @public
*/
export const cancelAssessmentById = (assessmentId) => {
   return new Promise(async (res, rej) => {
       try {
           let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.CANCEL_ASSESSMENT_BY_ID(assessmentId))
           console.log("Here in ass:",data)
           res({
               data: data.data,
               statusCode: data.statusCode,
               message: data.message
           })
       } catch (error) {
           rej(error)
       }
   })
}

/**
* @desc To pause assessment by assessmentId
* @param {string} assessmentId
* @public
*/
export const pauseAssessmentById = (assessmentId) => {
   return new Promise(async (res, rej) => {
       try {
           let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.PAUSE_ASSESSMENT_BY_ID(assessmentId))
           res({
               data: data.data,
               statusCode: data.statusCode,
               message: data.message
           })
       } catch (error) {
           rej(error)
       }
   })
}

/**
* @desc To resume assessment by assessmentId
* @param {string} assessmentId
* @public
*/
export const resumeAssessmentById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.RESUME_ASSESSMENT_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
* @desc To pause assessment by assessmentId
* @param {string} assessmentId
* @public
*/
export const pauseAssessmentByIdNew = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.PAUSE_ASSESSMENT_BY_ID_NEW(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
 }

/**
* @desc To resume assessment by assessmentId
* @param {string} assessmentId
* @public
*/
export const resumeAssessmentByIdNew = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.RESUME_ASSESSMENT_BY_ID_NEW(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
* @desc To start pre BP by assessmentId
* @param {string} assessmentId
* @public
*/
export const startPreBPById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.START_PRE_BP_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
 }
 
/**
* @desc To stop pre BP by assessmentId
* @param {string} assessmentId
* @public
*/
export const stopPreBPById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.STOP_PRE_BP_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
* @desc To start pre Baseline by assessmentId
* @param {string} assessmentId
* @public
*/
export const startPreBaselineById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.START_PRE_BASELINE_BY_ID(assessmentId))

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
 }
 
/**
* @desc To stop pre Baseline by assessmentId
* @param {string} assessmentId
* @public
*/
export const stopPreBaselineById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.STOP_PRE_BASELINE_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
* @desc To start Post BP by assessmentId
* @param {string} assessmentId
* @public
*/
export const startPostBPById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.START_POST_BP_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
 }
 
/**
* @desc To stop Post BP by assessmentId
* @param {string} assessmentId
* @public
*/
export const stopPostBPById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.STOP_POST_BP_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
* @desc To start Post Baseline by assessmentId
* @param {string} assessmentId
* @public
*/
export const startPostBaselineById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.START_POST_BASELINE_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
 }
 
/**
* @desc To stop Post Baseline by assessmentId
* @param {string} assessmentId
* @public
*/
export const stopPostBaselineById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.STOP_POST_BASELINE_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
* @desc To start VFT by assessmentId
* @param {string} assessmentId
* @public
*/
export const startVFTById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.START_VFT_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
 }

 /**
* @desc To stop VFT by assessmentId
* @param {string} assessmentId
* @public
*/
export const stopVFTById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.STOP_VFT_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
* @desc To start pre Questionnaire by assessmentId
* @param {string} assessmentId
* @public
*/
export const startPreQuestionnaireById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.START_PRE_QUESTIONNAIRE_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
 }
 
/**
* @desc To stop pre Questionnaire by assessmentId
* @param {string} assessmentId
* @public
*/
export const stopPreQuestionnaireById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.STOP_PRE_QUESTIONNAIRE_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
* @desc To start Post Questionnaire by assessmentId
* @param {string} assessmentId
* @public
*/
export const startPostQuestionnaireById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.START_POST_QUESTIONNAIRE_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
 }
 
/**
* @desc To stop Post Questionnaire by assessmentId
* @param {string} assessmentId
* @public
*/
export const stopPostQuestionnaireById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.STOP_POST_QUESTIONNAIRE_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
* @desc To recallibrate assessment by assessmentId
* @param {string} assessmentId
* @public
*/
export const recallibrateAssessmentById = (assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.put(API_CONSTANTS.ASSESSMENT.RECALLIBRATE_ASSESSMENT_BY_ID(assessmentId))
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}