import socketIOClient from "socket.io-client"
import { onProcessedDataUpdate } from "./processDataUpdate";
import { onPauseAssessment } from "./pauseAssessment";
import { onResumeAssessment } from "./resumeAssessment";
import { onAssessmentReportConnect } from "./connect";
import { onProcessedDataDisconnect } from "./disconnect";
import { onAssessmentComplete } from "./completed";
import { onBlindSpotRepeat, onBlindSpotRepeatFailure } from "./blindSpotRepeat";
import { onAssessmentCancelled } from "./cancelled";
import { onHmdAutoPause } from "./hmdAutoPause";
import { onPauseStatusesFromDevice } from "./pauseStatusesFromDevice";
import { onWarningStatusesFromDevice } from "./warningStatusesFromDevice";
import { onDeviceMismatch } from "../hmd/deviceMismatch";
import CONFIG from "../../config";

export const connectAssessmentNamespaceSocket = (assessmentId) => {
    const socketConnectUrl = CONFIG.CONSTANTS.ASSESSMENTS_SOCKET_URL
    const socket = socketIOClient(socketConnectUrl, { transports: ['websocket'] })

    socket.on("connect", () => onAssessmentReportConnect(socket, assessmentId))

    socket.on("blindspotFailure", data => onBlindSpotRepeatFailure(data))

    socket.on("blindspotRepeatYes", data => onBlindSpotRepeat(data))
    socket.on("blindspotRepeatNo", data => onBlindSpotRepeat(data))

    socket.on("assessmentPaused", data => onPauseAssessment(data))

    socket.on("assessmentResumed", data => onResumeAssessment(data))

    socket.on("processedDataUpdated", data => onProcessedDataUpdate(data))

    socket.on('completed', data => onAssessmentComplete(data))

    socket.on('disconnect', reason => onProcessedDataDisconnect(reason))

    socket.on('cancelled', reason => onAssessmentCancelled(reason))

    socket.on('hmdAutoPause', reason => onHmdAutoPause(reason))

    socket.on('pauseStatusesFromDevice', reason => onPauseStatusesFromDevice(reason))

    socket.on('warningStatusesFromDevice', reason => onWarningStatusesFromDevice(reason))

    return socket
}
