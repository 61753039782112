import * as Yup from 'yup';

export const SelectHmdForm = {
  hmd: '',
};

export const AddHmdForm = {
  name: '',
  deviceId: ''
};

export const SelectHmdSchema = Yup.object().shape({
  hmd: Yup.string().required('Language is required'),
});

export const AddHmdSchema = Yup.object().shape({
  name: Yup.string().required('HMD name is required'),
  deviceId: Yup.string().required('HMD device id is required'),
});
