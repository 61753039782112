import * as d3 from "d3"
import { EYE_COLOR_MAP, EYE_CHART_DEFAULT_FORMAT, EYE_COLOR_MAP_TDP_PDP } from '../../../shared/constants/chart.constants'

const getGrayScaleColor = value => {
    if (value <= 0.05) {
      return EYE_COLOR_MAP_TDP_PDP.DARK;
    } else if (value <= 1) {
      return EYE_COLOR_MAP_TDP_PDP.GREY;
    } else if (value <= 2) {
      return EYE_COLOR_MAP_TDP_PDP.LIGHT_GRAY;
    } else {
      return EYE_COLOR_MAP_TDP_PDP.LIGHT;
    }
  };

export const totalDeviationProbabilitiesChart =  (chartId, responseJson, chartOrientation, isModalOpen, grid) => {
    const rowCols = {
        '10-2': {
            rows: 22,
            cols: 23,
            scale: isModalOpen? 2: 2.5,
            lineWidth: 5
        }, 
        '24-2': {
            rows: 58,
            cols: 59,
            scale: isModalOpen? 6: 6,
            lineWidth: 10
        },
        '30-2': {
            rows: 60,
            cols: 63,
            scale: isModalOpen? 6: 6,
            lineWidth: 10
        },
    }

    const ROWS = rowCols[grid].rows || rowCols["24-2"].rows
    const COLS = rowCols[grid].cols || rowCols["24-2"].cols

    const SCALE = rowCols[grid].scale
    const LINE_WIDTH = rowCols[grid].lineWidth
    const ENLARGE = 13
    
    const width = COLS*100
    const height = ROWS*100

    const POINT_ADJUSTMENT = {
        x: -50,
        y: -50
    }

    const rw = 99
    const rh = 99

    let div
    let svg

    const renderChartFrame = ()=> {
        let data = [];
        let count = 0;

        document.getElementById(`${chartId}`).innerHTML = ""

        div = d3.select('#'+chartId)
        svg = div.append('svg')
                .attr('viewBox', `0 0 ${width} ${height}`)
                .attr('preserveAspectRatio', 'xMidYMid meet')

        for (let k = 0; k < ROWS; k += 1) {
            data.push(d3.range(COLS));
        }

        // Create a group for each row in the data matrix and
        // translate the group vertically
        let grp = svg.selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('id', (d, i) => `row-${(i+1)}`)
            .attr('transform', (d, i) => `translate(0, ${ 100 * i })`);

        // For each group, create a set of rectangles and bind 
        // them to the inner array (the inner array is already
        // binded to the group)
        grp.selectAll('rect')
            .data((d) => d)
            .enter()
            .append('rect')
            .attr('id', (d, i) => `col${i}`)
            .attr('x', (d, i) => `${100 * i + POINT_ADJUSTMENT.x}`)
            .attr('y', (d, i) => `${POINT_ADJUSTMENT.y}`)
            .attr('width', rw)
            .attr('height', rh)
            .attr('style', (d, i) => 'fill:white;');

        // Add the horizontal line _
        svg.append("line")
            .attr("x1", 0)
            .attr('y1', parseInt(((ROWS / 2)-1) * 100 - 0))
            .attr('x2', parseInt(ROWS * 100))
            .attr('y2', parseInt(((ROWS / 2)-1) * 100 - 0))
            .attr("style", "stroke:black; stroke-width:5;");


        // Add the vertical line |
        svg.append("line")
            .attr('x1', parseInt(((COLS-1) / 2) * 100 + 0))
            .attr('y1', parseInt(grid === '24-2'? (SCALE/2 * 100): 0))
            .attr('x2', parseInt(((COLS-1) / 2) * 100 + 0))
            .attr("y2", parseInt(((COLS-SCALE) / 2) * 100)* 2) // without *2 is the middle point
            .attr("style", `stroke:black; stroke-width:${LINE_WIDTH}px;`);

    }

    const plotThePoints = () => {

        for(var i=0; i<responseJson.length; i++){
            let { x, y, value } = responseJson[i]
            if(x>=0 && y>=0){
                plotInFirstQuadrant(x, y, value);
            } else if(x<0 && y>=0){
                plotInSecondQuadrant(x, y, value);
            } else if(x<0 && y<0){
                plotInThirdQuadrant(x, y, value);
            } else if(x>=0 && y<0){
                plotInFourthQuadrant(x, y, value);
            }
        }
    }

    const plotInFirstQuadrant = (x, y, value) => {
        x = x ? Math.abs(x) : x;
        y = y ? Math.abs(y) : y;

        var el = svg
        .selectAll('g#row-' + parseInt((ROWS/2) - y))
        .select('rect[id=col'+ parseInt(COLS/2 + x) +']')

        el.style("fill", (d)=> getGrayScaleColor(value));
        
    }

    const plotInSecondQuadrant = (x, y, value) => {
        x = x ? Math.abs(x) : x;
        y = y ? Math.abs(y) : y;

        var el = svg
        .selectAll('g#row-' + parseInt((ROWS/2) - y))
        .select('rect[id=col'+ parseInt(COLS/2 - x) +']')

        el.style("fill", (d)=> getGrayScaleColor(value));
    }

    const plotInThirdQuadrant = (x, y, value) => {
        x = x ? Math.abs(x) : x;
        y = y ? Math.abs(y) : y;

        var el = svg
        .selectAll('g#row-' + parseInt((ROWS/2) + y))
        .select('rect[id=col'+ parseInt(COLS/2 - x) +']')

        el.style("fill", (d)=> getGrayScaleColor(value));
    }

    const plotInFourthQuadrant = (x, y, value) => {
        x = x ? Math.abs(x) : x; 
        y = y ? Math.abs(y) : y; 

        let el = svg
        .selectAll('g#row-' + parseInt((ROWS/2) + y))
        .select('rect[id=col'+ parseInt(COLS/2 + x) +']')
            
        el.style("fill", (d)=> getGrayScaleColor(value));
    }

    renderChartFrame()
    plotThePoints()

}
