import * as Yup from 'yup'

export const LoginForm = {
    email: "",
    password: ""
}

export const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email')
      .required(),
    password: Yup.string()
      .required()
  })
