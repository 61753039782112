import CONFIG from "../../config"
import { updateActiveAssessment } from "../../actions/assessment.action"
import * as FlashMessage from "../../shared/utils/flashMessage"
import { WARNINGS } from "../../shared/constants/messages.constants"

export const onWarningStatusesFromDevice = (assessment = {}) => {
  if (assessment.status in WARNINGS.ASSESSMENTS) {
    FlashMessage.warning(WARNINGS.ASSESSMENTS[assessment.status])
  }

  if (assessment.status && assessment.id) {
    CONFIG.Store.dispatch(updateActiveAssessment(assessment.id,{ status: assessment.status }))
  }
}