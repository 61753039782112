import { SHARED } from "../../shared/constants"

/**
 * @desc Set auth token in the local storage
 * @public
 */
export const setAuthToken = (token = "") => {
  localStorage.setItem(SHARED.LOCAL_STORAGE.AUTH_TOKEN, token)
}

/**
 * @desc Get auth token from local storage
 * @public
 */
export const getAuthToken = () => {
  return localStorage.getItem(SHARED.LOCAL_STORAGE.AUTH_TOKEN)
}

/**
 * @desc Set auth refresh token in the local storage
 * @public
 */
export const setAuthRefreshToken = (token = "") => {
  localStorage.setItem(SHARED.LOCAL_STORAGE.AUTH_REFRESH_TOKEN, token)
}

/**
 * @desc Get auth token from local storage
 * @public
 */
export const getAuthRefreshToken = () => {
  return localStorage.getItem(SHARED.LOCAL_STORAGE.AUTH_REFRESH_TOKEN)
}

/**
 * @desc Remove the auth token entry from the local storage
 * @public
 */
export const clearAuthToken = () => {
  localStorage.removeItem(SHARED.LOCAL_STORAGE.AUTH_TOKEN)
  localStorage.removeItem(SHARED.LOCAL_STORAGE.AUTH_REFRESH_TOKEN)
}

/**
 * @desc Set the state of sidebar device panel 
 * @param {boolean} state
 * @public
 */
export const setDeviceSidebarState = (state = false) => {
  localStorage.setItem(SHARED.LOCAL_STORAGE.SHOW_DEVICE_SIDEBAR, state)
}

/**
 * @desc Get the state of sidebar device panel 
 * @public
 */
export const getDeviceSidebarState = () => {
  try {
    return JSON.parse(localStorage.getItem(SHARED.LOCAL_STORAGE.SHOW_DEVICE_SIDEBAR)) || false
  } catch (error) {
    return false
  }
}
