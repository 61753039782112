import Axios from "axios"
import { API_CONSTANTS } from "../../shared/constants/api.constants"

/**
 * @desc To authenticate user through its email and password.
 * @param {object} userData
 * @public
 */
export const authenticateUser = (userData) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.AUTH.LOGIN, userData)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
            console.log(data);
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To fetch user's details from the auth token present in local storage.
 * @public
 */
export const fetchMyDetails = () => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.AUTH.FETCH_MY_DETAILS)
            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}