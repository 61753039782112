import { toast } from "react-toastify"

export const error = (errorMessage) => {
    toast(errorMessage, { type: "error" })
}

export const success = (successMessage) => {
    toast(successMessage, { type: "success" })
}

export const warning = (warningMessage) => {
    toast(warningMessage, { type: "warning" })
}
