import * as HmdDeviceService from "../services/hmdDevice/hmdDevice.service"
import { LOADER, HMDDEVICES } from "../shared/constants/actions.constants"
import { SUCCESS, ERROR } from '../shared/constants/messages.constants'
import * as FlashMessage from "../shared/utils/flashMessage"

/**
 * @desc To fetch all Hmd devices and store it into Redux Store
 * @public
 */
export const resetDevicesStatus = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      await HmdDeviceService.resetDevicesStatus()

      dispatch(setLoader(false))
    } catch (error) {
      dispatch(setLoader(false))
    }
  }
}

/**
 * @desc To fetch all Hmd devices and store it into Redux Store
 * @public
 */
export const updateCalibrationInHmdDevice = (data) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      await HmdDeviceService.updateCalibrationInHmdDevice(data)

      dispatch(setLoader(false))
    } catch (error) {
      dispatch(setLoader(false))
    }
  }
}

/**
 * @desc To fetch all Hmd devices and store it into Redux Store without loading animations
 * @public
 */
export const getHmdDevicesWithoutLoading = () => {
  return async (dispatch) => {
    try {

      let { data } = await HmdDeviceService.getHmdDevicesList()

      onSuccessHmdDevicesList(dispatch, data, false)
    } catch (error) {
      onErrorHmdDevicesList(dispatch, error, false)
    }
  }
}
/**
 * @desc To fetch all Hmd devices and store it into Redux Store
 * @public
 */
export const getHmdDevices = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      let { data } = await HmdDeviceService.getHmdDevicesList()

      onSuccessHmdDevicesList(dispatch, data)
    } catch (error) {
      onErrorHmdDevicesList(dispatch, error)
    }
  }
}

/**
 * @desc To fetch Hmd by id and store it into Redux Store
 * @public
 */
export const getHmdDeviceById = (id) => {
  return async (dispatch) => {
    try {
      let { data } = await HmdDeviceService.getHmdDeviceById(id)

      onSuccessHmdDeviceById(dispatch, data)
    } catch (error) {
      onErrorHmdDeviceById(dispatch, error)
    }
  }
}

export const setHmdDeviceById = () => {
  return async (dispatch) => {
    dispatch(setHmdDeviceByIdAction(null))
  }
}

/**
 * @desc To add Hmd device and 
 * @public
 */
export const addHmdDevice = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      const data = await HmdDeviceService.addHmdDevice(payload)
      onSuccessHmdDeviceAdd(dispatch, data.data)
    } catch (error) {
      onErrorHmdDeviceAdd(dispatch, error)
    }
  }
}

/**
 * @desc To delete Hmd device and update it into Redux Store
 * @public
 */
export const deleteHmdDevice = (deviceId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true))

      await HmdDeviceService.deleteHmdDevice(deviceId)

      onSuccessHmdDeviceDelete(dispatch, deviceId)
    } catch (error) {
      onErrorHmdDeviceDelete(dispatch, error)
    }
  }
}

export const selectHmdDevice = (deviceId) => {
  return (dispatch) => {
    dispatch(onSelectHmdDevice(deviceId))
  }
}

// Private Methods
/**
 * @desc To fetch all states based on countryId
 * @param {object} dispatch
 * @param {object} countryId
 * @private
 */
const onSuccessHmdDevicesList = (dispatch, hmdDevices, checkLoadingScreen) => {
  dispatch(setHmdDeiceListAction(hmdDevices))
  if(checkLoadingScreen) {
    dispatch(setLoader(false))
  }
}

/**
 * @desc To handle error while fetching HMD devices list
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorHmdDevicesList = (dispatch, error, checkLoadingScreen) => {
  if(checkLoadingScreen) {
    dispatch(setLoader(false))
  }
}

/**
 * @desc To fetch hmd by id
 * @param {object} dispatch
 * @param {object} countryId
 * @private
 */
const onSuccessHmdDeviceById = (dispatch, hmdDetails) => {
  dispatch(setHmdDeviceByIdAction(hmdDetails))
}

/**
 * @desc To handle error while fetching HMD by id
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorHmdDeviceById = (dispatch, error) => {
  console.log(error)
}

/**
 * @desc Action to set loader
 * @param {boolean} flag
 * @private
 */
const setLoader = (flag) => {
  return { 
    type: LOADER.SET_LOADER, 
    payload: flag
  }
}

/**
 * @desc Action to set Hmd List into store
 * @param {Array} list
 * @private
 */
const setHmdDeiceListAction = (list = []) => {
  return {
    type: HMDDEVICES.SET_HMD_LIST,
    payload: list
  }
}

/**
 * @desc Action to set Hmd by id
 * @param {Object} data
 * @private
 */
const setHmdDeviceByIdAction = (data = null) => {
  return {
    type: HMDDEVICES.SET_HMD_DEVICE_BY_ID,
    payload: data
  }
}

const addHmdDeviceToStore = (payload) => {
  return {
    type: HMDDEVICES.ADD_HMD,
    payload: payload
  }
}

const deleteHmdDeviceById = (deviceId) => {
  return {
    type: HMDDEVICES.DELETE_HMD,
    payload: deviceId
  }
}

const onSuccessHmdDeviceAdd = (dispatch, payload) => {
  dispatch(setLoader(false))
  dispatch(addHmdDeviceToStore(payload))
  FlashMessage.success(SUCCESS.HMD_DEVICE_SUCCESSFULLY_CREATED)
}

const onSuccessHmdDeviceDelete = (dispatch, deviceId) => {
  dispatch(setLoader(false))
  dispatch(deleteHmdDeviceById(deviceId))
}

const onErrorHmdDeviceDelete = (dispatch, deviceId) => {
  dispatch(setLoader(false))
}

const onErrorHmdDeviceAdd = (dispatch, deviceId) => {
  dispatch(setLoader(false))
}

const onSelectHmdDevice = (deviceId) => {
  return {
    type: HMDDEVICES.SELECT_HMD_DEVICE,
    payload: deviceId
  }
}