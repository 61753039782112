import * as AssessmentService from "../services/assessment/assessment.service"
import * as HmdDeviceService from "../services/hmdDevice/hmdDevice.service"
import { LOADER, ASSESSMENT } from "../shared/constants/actions.constants"
import { DYNAMIC_ROUTES } from "../shared/constants/routes.constants"
import { ASSESSMENT_STATUSES, NO_RECORD_MSG } from "../shared/constants/shared.constants"
import * as FlashMessage from "../shared/utils/flashMessage"
import { SUCCESS, ERROR } from '../shared/constants/messages.constants'
import { getHmdDevicesWithoutLoading, resetDevicesStatus } from './hmdDevices.action';

/**
 * @desc To fetch assessments by patient Id
 * @param {string} patientId
 * @public
 */
export const getAssessmentsByPatientId = (patientId, limit=0, sort=null) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data } = await AssessmentService.getAssessmentsByPatientId(patientId, limit, sort)

      onSuccessAssessmentsByPatientId(dispatch, patientId, data)
    } catch (error) {
      onErrorAssessmentsByPatientId(dispatch, error)
    }
  }
}

/**
 * Add a new assessment and handle device mismatch check.
 *
 * @param {object} assessment - The assessment data to be added.
 * @param {function} dispatch - The dispatch function for Redux actions.
 */
export const addAssessment = ( assessment, callback ) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true));

      const { data } = await AssessmentService.createAssessment(assessment);

      const assessmentId = data?.id || ''
      await waitForDeviceMismatchCheck(assessment.hmdId, assessmentId);

      onSuccessAddAssessment(dispatch, data);

      if (callback){
        callback()
      }

    } catch (error) {
      handleAssessmentAddError(dispatch, error);
      throw error;
    }
  };
};
/**
 * Wait for the device mismatch check with a 5-second delay.
 *
 * @param {string} hmdId - The ID of the HMD for the check.
 * @returns {Promise<void>}
 */
const waitForDeviceMismatchCheck = async hmdId => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        await HmdDeviceService.checkHmdDeviceMismatch(hmdId);
        resolve();
      } catch (error) {
        reject(error);
      }
    }, 5000);
  });
};

/**
 * Handle errors and dispatch appropriate actions.
 *
 * @param {function} dispatch - The dispatch function for Redux actions.
 * @param {Error} error - The error object to be handled.
 */
const handleAssessmentUpdateError = (dispatch, error) => {
  if (isDeviceMismatchError(error)) {
    onErrorDeviceMismatch(dispatch, error);
  } else {
    onErrorUpdateAssessment(dispatch, error);
  }
};

/**
 * Handle errors and dispatch appropriate actions.
 *
 * @param {function} dispatch - The dispatch function for Redux actions.
 * @param {Error} error - The error object to be handled.
 */
const handleAssessmentAddError = (dispatch, error) => {
  if (isDeviceMismatchError(error)) {
    onErrorDeviceMismatch(dispatch, error);
  } else {
    onErrorAddAssessment(dispatch, error);
  }
};

/**
 * Handle errors and dispatch appropriate actions.
 *
 * @param {function} dispatch - The dispatch function for Redux actions.
 * @param {Error} error - The error object to be handled.
 */
const handleError = (dispatch, error) => {
  if (isDeviceMismatchError(error)) {
    onErrorDeviceMismatch(dispatch, error);
  } else {
    onErrorAddAssessment(dispatch, error);
  }
};

/**
 * Check if the error is related to device mismatch.
 *
 * @param {Error} error - The error object to check.
 * @returns {boolean} True if the error is related to device mismatch, otherwise false.
 */
const isDeviceMismatchError = error => {
  return (
    error?.response?.data?.message?.[Symbol.iterator] &&
    [...error?.response?.data?.message].includes(ERROR.PLEASE_CONNECT_THE_CORRECT_DEVICE)
  );
};


/**
 * @desc To update assessment object and add updated object into store
 * @param {object} assessment
 * @public
 */
export const updateAssessment = ( assessment, callback ) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true));

      // delete assessment.status;
      delete assessment.processed;
      delete assessment.patientId;
      delete assessment.userId;
      delete assessment.visitedOn;

      let { data } = await AssessmentService.updateAssessment(assessment);

      // const assessmentId = data?.id || ''
      // await waitForDeviceMismatchCheck(assessment.hmdId, assessmentId);

      onSuccessUpdateAssessment(dispatch, data);

      // if (callback){
      //   callback()
      // }
      
    } catch (error) {
      handleAssessmentUpdateError(dispatch, error);
    }
  };
};

/**
 * @desc To update assessment object and add updated object into store
 * @param {object} assessment
 * @public
 */
export const updateAssessmentStatus = assessment => {
  return async dispatch => {
    try {
      dispatch(setLoader(true));

      let { data } = await AssessmentService.updateAssessmentStatus(assessment);

      onSuccessUpdateAssessment(dispatch, data);
    } catch (error) {
      if(error?.response?.data?.message?.[Symbol.iterator] && [...error?.response?.data?.message].includes(ERROR.CLICKER_IS_NOT_WORKING)){
        onStatusClickerNotWorking(dispatch, {});
      } else {
        onErrorUpdateAssessment(dispatch, error);
      }
    }
  };
};

/**
 * @desc To update assessment object and add updated object into store
 * @param {object} assessment
 * @public
 */
export const startAssessment = ({ patientId, assessmentId }, history) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      await AssessmentService.startAssessment({ patientId, assessmentId })

      onSuccessStartAssessment(dispatch, history, { patientId, assessmentId })
    } catch (error) {
      onErrorStartAssessment(dispatch, error)
    }
  }
}

/**
 * @desc Action to set assessment object into store
 * @param {object} assessment
 * @public
 */
export const addActiveAssessment = (assessment) => {
  return {
    type: ASSESSMENT.ADD_ACTIVE_ASSESSMENT,
    payload: assessment
  }
}

/**
 * @desc Action to set assessment object into store
 * @param {object} assessment
 * @public
 */
export const addActiveAssessmentsByPatientId = (patientId, assessments) => {
  const assessmentsByPatientIdObject = {}
  assessmentsByPatientIdObject[patientId] = assessments

  return {
    type: ASSESSMENT.ADD_ACTIVE_ASSESSMENTS_BY_PATIENT_ID,
    payload: assessmentsByPatientIdObject
  }
}

/**
 * @desc Actions to reset assessments's state from store.
 * @public
 */
export const clearStateAfterAssessmentConf = () => {
  return async dispatch => {
    dispatch(addActiveAssessment(null))
  }
}

/**
 * @desc Actions to reset user's state from store.
 * @public
 */
export const clearStateAfterAssessmentInitiate = () => {
  return async dispatch => {
    dispatch(addActiveAssessment(null))
    dispatch(setActiveAssessmentProcessedData(null))
  }
}

export const setActiveAssessmentProcessedData = (data) => {
  return {
    type: ASSESSMENT.ADD_ACTIVE_ASSESSMENT_PROCESSED_DATA,
    payload: data
  }
}

export const getAssessmentById = (assessmentId, showLoader = true) => {
  return async (dispatch, getState) => {
    try {
      if (showLoader) dispatch(setLoader(true));

      let { data: assessment } = await AssessmentService.getAssessmentById(
        assessmentId,
      );
      
      if(assessment.hmdDeviceStatus === 'clickerFailure'){
        FlashMessage.error('Clicker is not working');
        dispatch(resetDevicesStatus())
        dispatch(getHmdDevicesWithoutLoading());
      }

      delete assessment.hmdDeviceStatus;
      delete assessment._id
      onSuccessAssessmentById(dispatch, assessment);
    } catch (error) {
      onErrorAssessmentById(dispatch, error);
    }
  };
};

export const cancelAssessmentById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.cancelAssessmentById(assessmentId)
  
      onCancelAssessmentById(dispatch, assessment)
    } catch (error) {
      onErrorCancelAssessmentById(dispatch, error)
    }
  }
}

export const recallibrateAssessmentById = (assessmentId, history) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.recallibrateAssessmentById(assessmentId)

      onSuccessRecallibrateAssessmentById(dispatch, assessment, history)
    } catch (error) {
      onErrorRecallibrateAssessmentById(dispatch, error)
    }
  }
}

export const pauseAssessmentById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.pauseAssessmentById(assessmentId)
  
      onPauseAssessmentById(dispatch, assessment)
    } catch (error) {
      onErrorPauseAssessmentById(dispatch, error)
    }
  }
}

export const resumeAssessmentById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.resumeAssessmentById(assessmentId)
  
      onSuccessResumeAssessmentById(dispatch, assessment)
    } catch (error) {
      onErrorResumeAssessmentById(dispatch, error)
    }
  }
}

export const pauseAssessmentByIdNew = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.pauseAssessmentByIdNew(assessmentId)
  
      onPauseAssessmentByIdNew(dispatch, assessment)
    } catch (error) {
      onErrorPauseAssessmentByIdNew(dispatch, error)
    }
  }
}

export const resumeAssessmentByIdNew = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.resumeAssessmentByIdNew(assessmentId)
  
      onSuccessResumeAssessmentByIdNew(dispatch, assessment)
    } catch (error) {
      onErrorResumeAssessmentByIdNew(dispatch, error)
    }
  }
}

export const startPreBPById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.startPreBPById(assessmentId)
  
      onStartPreBPById(dispatch, assessment)
    } catch (error) {
      onErrorStartPreBPById(dispatch, error)
    }
  }
}

export const stopPreBPById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.stopPreBPById(assessmentId)
  
      onSuccessStopPreBPById(dispatch, assessment)
    } catch (error) {
      onErrorStopPreBPById(dispatch, error)
    }
  }
}

export const startPreBaselineById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.startPreBaselineById(assessmentId)
  
      onStartPreBaselineById(dispatch, assessment)
    } catch (error) {
      onErrorStartPreBaselineById(dispatch, error)
    }
  }
}

export const stopPreBaselineById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.stopPreBaselineById(assessmentId)
  
      onSuccessStopPreBaselineById(dispatch, assessment)
    } catch (error) {
      onErrorStopPreBaselineById(dispatch, error)
    }
  }
}


export const startPreQuestionnaireById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.startPreQuestionnaireById(assessmentId)
  
      onStartPreQuestionnaireById(dispatch, assessment)
    } catch (error) {
      onErrorStartPreQuestionnaireById(dispatch, error)
    }
  }
}

export const stopPreQuestionnaireById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.stopPreQuestionnaireById(assessmentId)
  
      onSuccessStopPreQuestionnaireById(dispatch, assessment)
    } catch (error) {
      onErrorStopPreQuestionnaireById(dispatch, error)
    }
  }
}

export const startVFTById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.startVFTById(assessmentId)
  
      onStartVFTById(dispatch, assessment)
    } catch (error) {
      onErrorStartVFTById(dispatch, error)
    }
  }
}

export const stopVFTById = assessmentId => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let {
        data: currentAssesment,
      } = await AssessmentService.getAssessmentById(assessmentId);
      if (currentAssesment.status === ASSESSMENT_STATUSES.COMPLETED) {
        onFinishTestById(dispatch, currentAssesment);
      } else {
        let { data: assessment } = await AssessmentService.stopVFTById(
          assessmentId,
        );
        onStopVFTById(dispatch, assessment);
      }
    } catch (error) {
      onErrorStopVFTById(dispatch, error)
    }
  }
}

export const startPostBPById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.startPostBPById(assessmentId)
  
      onStartPostBPById(dispatch, assessment)
    } catch (error) {
      onErrorStartPostBPById(dispatch, error)
    }
  }
}

export const stopPostBPById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.stopPostBPById(assessmentId)
  
      onSuccessStopPostBPById(dispatch, assessment)
    } catch (error) {
      onErrorStopPostBPById(dispatch, error)
    }
  }
}

export const startPostBaselineById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.startPostBaselineById(assessmentId)
  
      onStartPostBaselineById(dispatch, assessment)
    } catch (error) {
      onErrorStartPostBaselineById(dispatch, error)
    }
  }
}

export const stopPostBaselineById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.stopPostBaselineById(assessmentId)
  
      onSuccessStopPostBaselineById(dispatch, assessment)
    } catch (error) {
      onErrorStopPostBaselineById(dispatch, error)
    }
  }
}

export const startPostQuestionnaireById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.startPostQuestionnaireById(assessmentId)
  
      onStartPostQuestionnaireById(dispatch, assessment)
    } catch (error) {
      onErrorStartPostQuestionnaireById(dispatch, error)
    }
  }
}

export const stopPostQuestionnaireById = (assessmentId) => {
  return async dispatch => {
    try {
      dispatch(setLoader(true))

      let { data: assessment } = await AssessmentService.stopPostQuestionnaireById(assessmentId)
  
      onSuccessStopPostQuestionnaireById(dispatch, assessment)
    } catch (error) {
      onErrorStopPostQuestionnaireById(dispatch, error)
    }
  }
}

/**
 * @desc Action to update active assessment's status
 * @param {boolean} flag
 * @private
 */
export const updateActiveAssessment = (assessmentId, data) => {
  if (Object.values(ASSESSMENT_STATUSES || {}).includes(data.status)) {

    return { 
      type: ASSESSMENT.UPDATE_ACTIVE_ASSESSMENTS_STATUS, 
      payload: { assessmentId, ...data }
    }
  }
}


// Private Methods
/**
 * @desc Action to set loader state
 * @param {boolean} flag
 * @private
 */
const setLoader = (flag) => {
  return { 
    type: LOADER.SET_LOADER, 
    payload: flag
  }
}

/**
 * @desc To update store states when assessment is successfully added
 * @param {object} dispatch
 * @param {object} assessment
 * @private
 */
const onSuccessAddAssessment = (dispatch, assessment) => {
  dispatch(setLoader(false))
  dispatch(addActiveAssessment(assessment))
  FlashMessage.success(SUCCESS.ASSESSMENT_SUCCESSFULLY_CREATED)
}

/**
 * @desc To notify user when assessment is error in for device mismatch
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorDeviceMismatch = (dispatch, error) => {
  dispatch(setLoader(false));
  FlashMessage.error(ERROR.PLEASE_CONNECT_THE_CORRECT_DEVICE);
};

/**
 * @desc To notify user when assessment is error in assessment addition
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorAddAssessment = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.ASSESSMENT_WAS_NOT_CREATED)
}

/**
 * @desc To notify user when assessments based on patient id is fetched successfully
 * @param {object} dispatch
 * @param {object} assessment
 * @private
 */
const onSuccessAssessmentsByPatientId = (dispatch, patientId, assessments) => {
  dispatch(setLoader(false))
  dispatch(addActiveAssessmentsByPatientId(patientId, assessments))
}

/**
 * @desc To notify user when assessments based on patient id is not fetched successfully
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorAssessmentsByPatientId = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.ASSESSMENTS_BY_PATIENT_ID_WAS_NOT_SUCCESSFULLY_FETCHED)
}

/**
 * @desc To update store states when assessment is successfully updated.
 * @param {object} dispatch
 * @param {object} assessment
 * @private
 */
const onStatusClickerNotWorking = (dispatch, assessment) => {
  dispatch(setLoader(false));
  FlashMessage.error(ERROR.CLICKER_IS_NOT_WORKING);
};

/**
 * @desc To update store states when assessment is successfully updated.
 * @param {object} dispatch
 * @param {object} assessment
 * @private
 */
const onSuccessUpdateAssessment = (dispatch, assessment) => {
  dispatch(setLoader(false))
  dispatch(addActiveAssessment(assessment))
  FlashMessage.success(SUCCESS.ASSESSMENT_SUCCESSFULLY_UPDATED)
}

/**
 * @desc To notify user when there is any error while updating assessment
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorUpdateAssessment = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.ASSESSMENT_WAS_NOT_UPDATED)
}

/**
 * @desc To update store states when assessment is successfully updated.
 * @param {object} dispatch
 * @param {object} assessment
 * @private
 */
const onSuccessStartAssessment = (dispatch, history, { patientId, assessmentId }) => {
  dispatch(setLoader(false))
  // history.push(DYNAMIC_ROUTES.INITIATE_ASSESSMENT(patientId, assessmentId))
  FlashMessage.success(SUCCESS.START_ASSESSMENT)
}

/**
 * @desc To notify user when there is any error while updating assessment
 * @param {object} dispatch
 * @param {object} error
 * @private
 */
const onErrorStartAssessment = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.START_ASSESSMENT_FAILED)
}

const onSuccessAssessmentById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))

  if (assessment && assessment.data && assessment.data.processed && (assessment.data.processed.OS || assessment.data.processed.OD)) {
    dispatch(setActiveAssessmentProcessedData(assessment.data.processed))
  }

  dispatch(setLoader(false))
}

const onErrorAssessmentById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(error)
}

const onCancelAssessmentById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(setLoader(false))
}

const onErrorCancelAssessmentById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.CANCEL_ASSESSMENT_FAILED)
}

const onSuccessRecallibrateAssessmentById = (dispatch, assessment, history) => {
  dispatch(setLoader(false))
  history.push(DYNAMIC_ROUTES.ASSESSMENT_WITH_RECALLIBRATION(assessment.patientId, assessment.id))
}

const onErrorRecallibrateAssessmentById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.RECALLIBRATE_ASSESSMENT_FAILED)
}

const onPauseAssessmentById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(setLoader(false))
}

const onErrorPauseAssessmentById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.PAUSE_ASSESSMENT_FAILED)
}

const onSuccessResumeAssessmentById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(setLoader(false))
  // FlashMessage.success(SUCCESS.RESUME_ASSESSMENT_SUCCESSFULLY)
}

const onErrorResumeAssessmentById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.RESUME_ASSESSMENT_FAILED)
}

const onPauseAssessmentByIdNew = (dispatch, assessment) => {
  dispatch(updateActiveAssessment(assessment, { status: ASSESSMENT_STATUSES.PAUSED_NEW }))
  dispatch(setLoader(false))
}

const onErrorPauseAssessmentByIdNew = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.PAUSE_ASSESSMENT_FAILED)
}

const onSuccessResumeAssessmentByIdNew = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(setLoader(false))
}

const onErrorResumeAssessmentByIdNew = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.RESUME_ASSESSMENT_FAILED)
}

const onStartPreBPById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment, { status: ASSESSMENT_STATUSES.START_PRE_BP }))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.PRE_BP_COLLECTION_STARTED_SUCCESSFULLY)
}

const onErrorStartPreBPById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.START_PRE_BP_FAILED)
}

const onSuccessStopPreBPById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.STOP_PRE_BP }))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.PRE_BP_COLLECTION_STOPED_SUCCESSFULLY)
}
     
const onErrorStopPreBPById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.STOP_PRE_BP_FAILED)
}

const onStartPreBaselineById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.START_PRE_BASELINE }))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.PRE_BASELINE_COLLECTION_STARTED_SUCCESSFULLY)
}

const onErrorStartPreBaselineById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.START_PRE_BASELINE_FAILED)
}

const onSuccessStopPreBaselineById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.STOP_PRE_BASELINE }))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.PRE_BASELINE_COLLECTION_STOPED_SUCCESSFULLY)
}
     
const onErrorStopPreBaselineById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.STOP_PRE_BASELINE_FAILED)
}

const onStartPreQuestionnaireById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.START_PRE_QUESTIONNIRE }))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.PRE_QUESTIONNAIRE_COLLECTION_STARTED_SUCCESSFULLY)
}

const onErrorStartPreQuestionnaireById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.START_PRE_QUESTIONNAIRE_FAILED)
}

const onSuccessStopPreQuestionnaireById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.STOP_PRE_QUESTIONNIRE }))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.PRE_QUESTIONNAIRE_COLLECTION_STOPED_SUCCESSFULLY)
}
     
const onErrorStopPreQuestionnaireById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.STOP_PRE_QUESTIONNAIRE_FAILED)
}

const onStartPostBPById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.START_POST_BP }))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.POST_BP_COLLECTION_STARTED_SUCCESSFULLY)
}

const onErrorStartPostBPById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.START_POST_BP_FAILED)
}

const onSuccessStopPostBPById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.STOP_POST_BP }))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.POST_BP_COLLECTION_STOPED_SUCCESSFULLY)
}
     
const onErrorStopPostBPById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.STOP_POST_BP_FAILED)
}

const onStartPostBaselineById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.START_POST_BASELINE }))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.POST_BASELINE_COLLECTION_STARTED_SUCCESSFULLY)
}

const onErrorStartPostBaselineById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.START_POST_BASELINE_FAILED)
}

const onSuccessStopPostBaselineById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.STOP_POST_BASELINE }))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.POST_BASELINE_COLLECTION_STOPED_SUCCESSFULLY)
}
     
const onErrorStopPostBaselineById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.STOP_POST_BASELINE_FAILED)
}

const onStartPostQuestionnaireById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.START_POST_QUESTIONNIRE }))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.POST_QUESTIONNAIRE_COLLECTION_STARTED_SUCCESSFULLY)
}

const onErrorStartPostQuestionnaireById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.START_POST_QUESTIONNAIRE_FAILED)
}

const onSuccessStopPostQuestionnaireById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.STOP_POST_QUESTIONNIRE }))  
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.POST_QUESTIONNAIRE_COLLECTION_STOPED_SUCCESSFULLY)
}
     
const onErrorStopPostQuestionnaireById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.STOP_POST_QUESTIONNAIRE_FAILED)
}

const onStartVFTById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.START_VFT }))
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.VFT_STARTED_SUCCESSFULLY)
}

const onErrorStartVFTById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.START_VFT_FAILED)
}

const onStopVFTById = (dispatch, assessment) => {
  dispatch(addActiveAssessment(assessment))
  dispatch(updateActiveAssessment(assessment,{ status: ASSESSMENT_STATUSES.STOP_VFT }))  
  dispatch(setLoader(false))
  FlashMessage.success(SUCCESS.VFT_STOPPED_SUCCESSFULLY)
}

const onErrorStopVFTById = (dispatch, error) => {
  dispatch(setLoader(false))
  FlashMessage.error(ERROR.STOP_VFT_FAILED)
}

const onFinishTestById = (dispatch, assessment) => {
  // dispatch(addActiveAssessment(assessment));
  // dispatch(
    // updateActiveAssessment(assessment, { status: ASSESSMENT_STATUSES.COMPLETED }),
  // );
  dispatch(addActiveAssessment(null))
  dispatch(setActiveAssessmentProcessedData(null))
  dispatch(setLoader(false));
  FlashMessage.success(SUCCESS.ASSESSMENT_SUCCESSFULLY_COMPLETED);
};

