export const AUTHTYPE = { 
  GET_USER_LOGIN_LOADING_START: "GET_USER_LOGIN_LOADING_START",
  GET_USER_LOGIN_LOADING_STOP: "GET_USER_LOGIN_LOADING_STOP",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_IS_ADMIN: "SET_IS_ADMIN",
  UPDATE_CURRENT_USER_SETTINGS: "UPDATE_CURRENT_USER_SETTINGS"
}

export const USERS = {
  GET_ALL_USERS: "GET_ALL_USERS",
  SET_USERS_OBJ: "SET_USERS_OBJ",
  ADD_USER_INTO_OBJ: "ADD_USER_INTO_OBJ",
  ADD_ACTIVE_USER: "ADD_ACTIVE_USER",
  DELETE_USER: "DELETE_USER",
  SET_EDITABLE_USER: "SET_EDITABLE_USER",
  SET_USERS_SEARCH_TEXT: "SET_USERS_SEARCH_TEXT",
  SET_USERS_SORT: "SET_USERS_SORT",
}

export const PATIENTS = {
  SET_PATIENTS_OBJ: "SET_PATIENTS_OBJ",
  SET_ADD_PATIENT_MODAL: "SET_ADD_PATIENT_MODAL",
  ADD_PATIENT_INTO_OBJ: "ADD_PATIENT_INTO_OBJ",
  SET_PATIENTS_IMPORT_TEST_INTO_OBJ: "SET_PATIENTS_IMPORT_TEST_INTO_OBJ",
  ADD_ACTIVE_PATIENT: "ADD_ACTIVE_PATIENT",
  DELETE_PATIENT: "DELETE_PATIENT",
  SET_PATIENT_SEARCH_TEXT: "SET_PATIENT_SEARCH_TEXT",
  SET_ASSESSMENT_DATE_RANGE: "SET_ASSESSMENT_DATE_RANGE",
  SET_PATIENT_LIST_SORT: "SET_PATIENT_LIST_SORT",
  SET_PATIENT_SIMPLE_LIST: "SET_PATIENT_SIMPLE_LIST",
  SET_PATIENT_PAGINATION: "SET_PATIENT_PAGINATION"
}

export const ASSESSMENT = {
  ADD_ASSESSMENT_INTO_OBJ: "ADD_ASSESSMENT_INTO_OBJ",
  ADD_ACTIVE_ASSESSMENT: "ADD_ACTIVE_ASSESSMENT",
  ADD_ACTIVE_ASSESSMENT_PROCESSED_DATA: "ADD_ACTIVE_ASSESSMENT_PROCESSED_DATA",
  ADD_ACTIVE_ASSESSMENTS_BY_PATIENT_ID: "ADD_ACTIVE_ASSESSMENTS_BY_PATIENT_ID",
  UPDATE_ACTIVE_ASSESSMENTS_STATUS: "UPDATE_ACTIVE_ASSESSMENTS_STATUS"
}

export const NOTES = {
  SET_NOTES: "SET_NOTES"
}

export const COUNTRY_STATE_CITY = {
  SET_COUNTRIES: "SET_COUNTRIES",
  SET_STATES_BY_COUNTRY_ID: "SET_STATES_BY_COUNTRY_ID"  
}

export const NOTIFICATION = {
  FLASH_MESSAGE: "FLASH_MESSAGE",
  CLEAR_CURRENT_FLASH_MESSAGE: "CLEAR_CURRENT_FLASH_MESSAGE"
}

export const LOADER = {
  SET_LOADER: "SET_LOADER"
}

export const MODALS = {
  SET_MODAL: "SET_MODAL",
  MODAL_TYPE: "MODAL_TYPE",
  ADD_PATIENT_MODAL: "ADD_PATIENT_MODAL",
  ADD_USER_MODAL: "ADD_USER_MODAL",
  EDIT_PATIENT_MODAL: "EDIT_PATIENT_MODAL",
  BLIND_SPOT_MODAL: "BLIND_SPOT_MODAL",
  PATIENTS_IMPORTED_TEST_VIEW_MODAL: "PATIENTS_IMPORTED_TEST_VIEW_MODAL",
  ADD_WORKLIST_MODAL: "ADD_WORKLIST_MODAL",
  EDIT_WORKLIST_MODAL: "EDIT_WORKLIST_MODAL",
  CONFIRMATION_MODAL: "CONFIRMATION_MODAL",
  CONFIRMATION_MODAL_TO_REMOVE_ALL_PATIENTS_FROM_WORKLIST: "CONFIRMATION_MODAL_TO_REMOVE_ALL_PATIENTS_FROM_WORKLIST",
  CONFIRMATION_MODAL_TO_DELETE_WORKLIST: "CONFIRMATION_MODAL_TO_DELETE_WORKLIST",
  CONFIRMATION_MODAL_TO_DELETE_PATIENT: "CONFIRMATION_MODAL_TO_DELETE_PATIENT",
  DND_FOLLOW_CHARTS: "DND_FOLLOW_CHARTS",
  DND_SINGLE_VIEW_CHARTS: "DND_SINGLE_VIEW_CHARTS",
  DND_OVERVIEW_VIEW_CHARTS: "DND_OVERVIEW_VIEW_CHARTS",
  CHART_VIEW_MODAL: "CHART_VIEW_MODAL",
  WARNING_MESSAGE_MODAL: "WARNING_MESSAGE_MODAL",
  IMPORT_TESTS_MODAL: "IMPORT_TESTS_MODAL",
  CALIBRATE_AND_CONFIGURATION_MODAL: "CALIBRATE_AND_CONFIGURATION_MODAL",
  CALIBRATE_MODAL: "CALIBRATE_MODAL",
  SELECT_HMD_MODAL: "SELECT_HMD_MODAL",
  WIFI_CONNECTION_MODAL: "WIFI_CONNECTION_MODAL",
  BLUETOOTH_CONNECTION_MODAL: "BLUETOOTH_CONNECTION_MODAL",
}

export const UI = {
  SET_DEVICE_SIDEBAR: "SET_DEVICE_SIDEBAR",
  SET_DEVICE_SIDEBAR_DISABLE: "SET_DEVICE_SIDEBAR_DISABLE"
}

export const HMDDEVICES = {
  SELECT_HMD_DEVICE: "SELECT_HMD_DEVICE",
  SET_HMD_LIST: "SET_HMD_LIST",
  SET_HMD_DEVICE_BY_ID: "SET_HMD_DEVICE_BY_ID",
  ADD_HMD: "ADD_HMD",
  DELETE_HMD: "DELETE_HMD"
}

export const WORKLISTS = {
  SET_WORKLISTS_OBJ: "SET_WORKLISTS_OBJ",
  SET_ADD_WORKLIST_MODAL: "SET_ADD_WORKLIST_MODAL",
  ADD_WORKLIST_INTO_OBJ: "ADD_WORKLIST_INTO_OBJ",
  ADD_ACTIVE_WORKLIST: "ADD_ACTIVE_WORKLIST",
  DELETE_WORKLIST: "DELETE_WORKLIST",
  SET_WORKLIST_SEARCH_TEXT: "SET_WORKLIST_SEARCH_TEXT",
  SET_WORKLIST_SORT: "SET_WORKLIST_SORT"
}

export const WIFI = {
  SET_WIFI_NETWORKS_LIST: 'SET_WIFI_NETWORKS_LIST',
  SET_WIFI_STATUS: 'SET_WIFI_STATUS',
  SET_WIFI_CONNECTION_STATUS: 'SET_WIFI_CONNECTION_STATUS',
  SET_BLUETOOTH_DEVICES_LIST: 'SET_BLUETOOTH_DEVICES_LIST',
  SET_BLUETOOTH_STATUS: 'SET_BLUETOOTH_STATUS',
  SET_BLUETOOTH_CONNECTION_STATUS: 'SET_BLUETOOTH_CONNECTION_STATUS',
};
