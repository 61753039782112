export const ROLES = [
        { value: 'admin' , name : "Admin" },
        { value: 'doctor' , name : "Doctor" },
        { value: 'technician' ,name : "Technician" }
]

export const ROLES_ENUM = {
        ADMIN: "admin",
        DOCTOR: "doctor",
        TECHNICIAN: "technician"
}

export const USER_SETTINGS_HOMEPAGE_ENUM = {
        PATIENTS: "patients",
        WORKLISTS: "worklists"
}

export const LANGUAGES = {
        ENGLISH: 'english',
        FRENCH: 'frensh'
}
export const LOCAL_STORAGE = {
        AUTH_TOKEN: "authToken",
        AUTH_REFRESH_TOKEN: "authRefreshToken",
        SHOW_DEVICE_SIDEBAR: "showDeviceSidebar"
}

export const DEBOUNCE_TIME = 500

export const CUSTOM_SESSION_TIMEOUT = {
        MIN: 1,
        MAX: 10080 // 1 week
}

export const NO_RECORD_MSG = {
        NO_HMD_FOUND: "No HMD Devices Available",
        NO_PATIENT_FOUND: "No Patients Available",
        NO_WORKLIST_FOUND: "No Worklists Available",
        NO_TEST_FOUND: "No Tests Available",
        NO_IMPORTED_TESTS_FOUND: "No Imported Test available.",
        NO_PREVIOUS_CONFIGURATIONS_FOUND: "No Previous Configurations Found"
}

export const ASSESSMENT_STATUSES = {
        NOT_DETECTED: 'not_detected',
        TRIAL: 'trial',
        TRIAL_COMPLETED: 'trialCompleted',
        CALIBRATE: 'calibrate',
        INITIATED: 'initiated',
        COMPLETED: "completed",
        CANCELLED_BY_USER: "cancelledByUser",
        CANCELLED_BY_DEVICE: "cancelledByDevice",
        PAUSED: 'paused',
        PARTIAL: "partial",
        RECALIBRATE: "reCalibrate",
        REBLIND_SPOT_TEST: "reBlindSpotTest",
        BLIND_SPOT_FAILURE: 'blindspotFailure',
        BLIND_SPOT_REPEAT: 'blindspotRepeat',
        BLIND_SPOT_REPEAT_COMPLETED: 'blindspotComplete',
        BLIND_SPOT_YES: 'blindspotRepeatYes',
        BLIND_SPOT_NO: 'blindspotRepeatNo',
        BLIND_SPOT_TEST: 'blindspotTest',
        FOVEAL_TEST: 'fovealTest',
        START_BASE_DATA_COLLECTION: "startbasedatacollection",
        STOP_BASE_DATA_COLLECTION: "stopbasedatacollection",
        RESUMED: 'resumed',
        //Pre and Post data collection status
        START_PRE_BP: "startPreBP",
        STOP_PRE_BP: "stopPreBP",
        START_PRE_BASELINE: "startPreBaseline",
        STOP_PRE_BASELINE: "stopPreBaseline",
        START_PRE_QUESTIONNIRE: "startPreQuestionnire",
        STOP_PRE_QUESTIONNIRE: "stopPreQuestionnire",
        START_VFT: "startVFT",
        STOP_VFT: "stopVFT",
        START_POST_BP: "startPostBP",
        STOP_POST_BP: "stopPostBP",
        START_POST_BASELINE: "startPostBaseline",
        STOP_POST_BASELINE: "stopPostBaseline",
        START_POST_QUESTIONNIRE: "startPostQuestionnire",
        STOP_POST_QUESTIONNIRE: "stopPostQuestionnire",        
        // Pause Status from Device
        TILT: "tilt",
        CONNECTION_LOST: "connectionlost",
        EYE_TRACKING_LOST: "eyetrackinglost",
        CLICKER_HOLD: "clickerhold",
        // Only Warning Status from Device
        UNRELIABLE: "unreliable",
        EXCESSIVE_BLINKING: "excessiveblinking"
}

export const STOPPED_BY_DEVICE_STATUSES = [
        ASSESSMENT_STATUSES.TILT,
        ASSESSMENT_STATUSES.CONNECTION_LOST,
        ASSESSMENT_STATUSES.EYE_TRACKING_LOST,
        ASSESSMENT_STATUSES.CLICKER_HOLD
]

export const PAUSE_BY_DEVICE_STATUSES = [
        ASSESSMENT_STATUSES.TILT,
        ASSESSMENT_STATUSES.CONNECTION_LOST,
        ASSESSMENT_STATUSES.EYE_TRACKING_LOST,
        ASSESSMENT_STATUSES.CLICKER_HOLD
]
    
export const WARNING_BY_DEVICE_STATUSES = [
        ASSESSMENT_STATUSES.UNRELIABLE,
        ASSESSMENT_STATUSES.EXCESSIVE_BLINKING
]

export const REPORT_PANELS = {
        SINGLE_VIEW: "SINGLE_VIEW",
        OVERVIEW: "OVERVIEW",
        PROGRESSION: "PROGRESSION"
}

export const TOOLTIP_TEXTS = {
        ADD_PATIENT_TO_WORKLIST: "Click to Add Patient into Worklist",
        REMOVE_PATIENT_FROM_WORKLIST: "Click to Remove Patient from Worklist",
        REFRESH: "Refresh",
}

export const PATIENTS_IMPORT_TEST = {
        VALID_FILE_FORMATS: ['application/pdf'],
        VALID_FILE_SIZE: 0,
        INVALID_FILE_FORMAT: "Please upload pdf files only"
}