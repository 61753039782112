import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { setModalWithType } from '../../../actions/modal.action';
import {
  getWifiNetworksList,
  getWifiStatus,
  getWifiConnectionStatus,
  connectToWifi
} from '../../../actions/wifiBluetoothConnection.action.js';

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  ssid: Yup.string().required('WiFi network is required'),
});

export const Wifi_BluetoothConnectionModal = props => {
  const { wifiNetworks, wifiStatus, isWifiConnected, getWifiNetworksList, getWifiStatus, getWifiConnectionStatus, connectToWifi } = props;

  const cancelClickHandler = () => {
    props.setModalWithType(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getWifiNetworksList();
      await getWifiStatus();
      await getWifiConnectionStatus();
    };

    fetchData();

    const interval = setInterval(fetchData, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [getWifiNetworksList, getWifiStatus, getWifiConnectionStatus]);

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">
          Wifi and Bluetooth Connection Management
        </h2>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={cancelClickHandler}
        >
          <span aria-hidden="true">
            <i className="fabicon-close"></i>
          </span>
        </button>
      </div>

      <div className="modal-body">
        <div className="test__monitoring mg-b-0 pb-0 border-bottom-0">
          <div className="test__monitoring--inner">
            <ul
              className="nav nav-pills nav__customtab"
              id="pills-tab"
              role="tablist"
            >
              <li className={`nav-item `}>
                <a
                  className={'nav-link active'}
                  href="#pills-wifi"
                  role="tab"
                  data-toggle="pill"
                >
                  Wifi Connection
                </a>
              </li>
              <li className={`nav-item `}>
                <a
                  className={'nav-link'}
                  href="#pills-bluetooth"
                  data-toggle="pill"
                  role="tab"
                >
                  Bluetooth Connection
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-3 test__content--scroll mcustomscrollbar">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-wifi"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <p>WiFi Enabled: {wifiStatus ? 'Yes' : 'No'}</p>
              <p>Connected to WiFi: {isWifiConnected ? 'Yes' : 'No'}</p>
              <Formik
                initialValues={{ ssid: '' }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  connectToWifi(values);
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, handleChange, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="ssid">WiFi Network</label>
                      <select
                        id="ssid"
                        name="ssid"
                        className={`form-control ${errors.ssid && touched.ssid ? 'is-invalid' : ''}`}
                        value={values.ssid}
                        onChange={handleChange}
                      >
                        <option value="" label="Select WiFi network" />
                        {wifiNetworks.map((network, index) => (
                          <option key={index} value={network}>
                            {network}
                          </option>
                        ))}
                      </select>
                      {errors.ssid && touched.ssid && (
                        <div className="invalid-feedback">{errors.ssid}</div>
                      )}
                    </div>
                    <button type="submit" className="btn btn-red">
                      Connect to WiFi
                    </button>
                  </form>
                )}
              </Formik>
            </div>
            <div
              className="tab-pane fade"
              id="pills-bluetooth"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <h5>Bluetooth Connection</h5>
              {/* <p>Bluetooth management functionality can be added here.</p> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalWithType,
      getWifiNetworksList,
      getWifiStatus,
      getWifiConnectionStatus,
      connectToWifi
    },
    dispatch,
  );

const mapStateToProps = ({ networks }) => ({
  wifiNetworks: networks.wifiList,
  wifiStatus: networks.wifiStatus,
  isWifiConnected: networks.isWifiConnected,
});

export default connect(mapStateToProps, mapDispatchToProps)(Wifi_BluetoothConnectionModal);
