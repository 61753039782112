import { default as React, useEffect, useState } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as CMD from '../../../shared/utils/graph/clusterMd'
import * as EA from '../../../shared/utils/graph/eventAnalysis'
import * as GT from '../../../shared/utils/graph/gazeTracker'
import * as PD from '../../../shared/utils/graph/patternDeviation'
import * as PDP from '../../../shared/utils/graph/patternDeviationProbabilities'
import * as SG from '../../../shared/utils/graph/sensitivityGrayScale'
import * as SV from '../../../shared/utils/graph/sensitivityValues'
import * as TD from '../../../shared/utils/graph/totalDeviation'
import * as TDP from '../../../shared/utils/graph/totalDeviationProbabilities'
import * as TA from '../../../shared/utils/graph/trendAnalysis'
import * as NF from '../../../shared/utils/graph/notFound'

import { CHART_NAMES } from '../../../shared/constants/chart.constants'
import { getChartScaleSize } from "../../assessmentReports/bottomSection/singleView/utils.js"
import { toCamelCase } from "../../../shared/utils/wrappers"


export const Eye = (props) => {

    const [transform, setTransform] = useState(getChartScaleSize(props.assessment?.configuration?.grid, props.setModal));
    const [height] = useState(130);
    const [width] = useState(130);
    const [gazeTrackerHeight] = useState('100%');
    const [gazeTrackerWidth] = useState('100%');
    const [left, setLeft] = useState(-55); // -150 when open in modal
    const [clusterMdLeft] = useState(100);
    const [top, setTop] = useState(-60); // -120 when open in modal
    const [position] = useState('relative');
    const [className] = useState('col-md-6');

    useEffect(() => {
        let { chartData, chartOrientation, chartName, chartId, top, left , setModal, assessment} = props

        if(top)
            setTop(top)

        if(left)
            setLeft(left)

        if(chartData && chartOrientation && chartName && chartId){
            if(chartName === CHART_NAMES.SENSITIVITY_VALUES){
                // instead of sensitivityValuesChart, use raw data
                SV.sensitivityValuesChart(chartId, chartData, chartOrientation, setModal, assessment?.configuration?.grid)
            } else if(chartName === CHART_NAMES.SENSITIVITY_GRAYSCALE){
                // instead of sensitivityValuesChart, use raw data
                SG.sensitivityGrayScaleChart(chartId, chartData, chartOrientation, setModal, assessment?.configuration?.grid)
            } else if(chartName === CHART_NAMES.EVENT_ANALYSIS){
                EA.eventAnalysisChart(chartId, chartData, chartOrientation)
            } else if(chartName === CHART_NAMES.TREND_ANALYSIS){
                TA.trendAnalysisChart(chartId, chartData, chartOrientation)
            } else if(chartName === CHART_NAMES.TOTAL_DEVIATION_VALUES){
                TD.totalDeviationChart(chartId, chartData, chartOrientation, setModal, assessment?.configuration?.grid)
            } else if(chartName === CHART_NAMES.PATTERN_DEVIATION_VALUES){
                PD.patternDeviationChart(chartId, chartData, chartOrientation, setModal, assessment?.configuration?.grid)
            } else if(chartName === CHART_NAMES.TOTAL_DEVIATION_PROBABILITIES){
                TDP.totalDeviationProbabilitiesChart(chartId, chartData, chartOrientation, setModal, assessment?.configuration?.grid)
            } else if(chartName === CHART_NAMES.PATTERN_DEVIATION_PROBABILITIES){
                PDP.patternDeviationProbabilitiesChart(chartId, chartData, chartOrientation, setModal, assessment?.configuration?.grid)
            } else if(chartName === CHART_NAMES.CLUSTER_MD) {
                // temporarily disable
                // CMD.clusterMdChart(chartId, chartData, chartOrientation)
                NF.notFoundChart(chartId)
            } else if(chartName === CHART_NAMES.GAZE_TRACKER) {
                // temporarily disable
                // GT.gazeTrackerChart(chartId, chartData, chartOrientation)
                NF.notFoundChart(chartId)
            } 
            else {
                NF.notFoundChart(chartId)
            }
        }

    }, [props.chartData])


    useEffect(()=>{
        if(top)
            setTop(props.top)

        if(left)
            setLeft(props.left)

        if(props.transform){
            setTransform(props.transform)
        }
    },[props.chartData, props.left , props.top, props.setModal, props.transform ])

    return (
        <div style={{
            width: '100%',
            padding: '5%'
        }}>
            { 
                props.chartId && props.chartName === CHART_NAMES.CLUSTER_MD && 
                (
                    <object 
                        id={props.chartId} 
                        data={`/assets/images/cluster-md-svg/cluster-md-${props.chartOrientation === 'OS' ? 'left': 'right'}-eye.svg`} 
                        style={{ 
                            transform:`scale(${(props.transform || transform)})`, 
                            height: (props.height || height), 
                            position:(props.position || position), 
                            left:(props.left || clusterMdLeft), 
                            top:(props.top || "-30px"),
                        }} 
                        type="image/svg+xml">
                    </object>
                )
            }
            { 
                props.chartId && props.chartName !== CHART_NAMES.GAZE_TRACKER && props.chartName !== CHART_NAMES.CLUSTER_MD &&                 
                (
                    <div 
                        className="mx-auto" 
                        id={props.chartId} 
                        style={{ 
                            // transform:`scale(${(transform)})`, 
                            // height: (props.height || height), 
                            // width: (props.width || width), 
                            // left:(props.left || left), 
                            // top:(props.top || top), 
                            // position:(props.position || position) ,
                            // ...(props.transformOrigin && { transformOrigin: props.transformOrigin })
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                        >  
                                                  
                    </div>
                ) 
            }
            { 
                props.chartId && props.chartName === CHART_NAMES.GAZE_TRACKER &&                 
                (
                    <div id={props.chartId} style={{width: (props.width || gazeTrackerWidth), height: (props.height || gazeTrackerHeight)}} className={props?.className}></div>
                ) 
            }
        </div>
    )
}

const mapStateToProps = ({ assessment, modals, patients }) => ({
    setModal: modals.set,
  })
  
export default connect(mapStateToProps)(Eye)
