import Axios from "axios"
import { API_CONSTANTS } from "../../shared/constants/api.constants"

/**
 * @desc add new hmd device
 * @public
 */
export const addHmdDevice = (payload) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.post(API_CONSTANTS.HMD_DEVICE.ADD_NEW_HMD_DEVICE, payload)

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To fetch all Hmd Devices list
 * @public
 */
export const getHmdDevicesList = () => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.HMD_DEVICE.GET_ALL_HMD_DEVICES)

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To fetch Hmd Device By Id
 * @public
 */
export const getHmdDeviceById = (id) => {
    return new Promise(async (res, rej) => {
        try {
            let { data } = await Axios.get(API_CONSTANTS.HMD_DEVICE.GET_HMD_BY_ID(id))

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To reset all devices to original status
 * @public
 */
export const resetDevicesStatus = () => {
    return new Promise(async (res, rej) => {
        try {
            await Axios.get(API_CONSTANTS.HMD_DEVICE.RESET_DEVICES)

            res({})
        } catch (error) {
            rej(error)
        }
    })
}

export const updateCalibrationInHmdDevice = (data) => {
    return new Promise(async (res, rej) => {
        try {
            await Axios.post(API_CONSTANTS.HMD_DEVICE.UPDATE_DEVICE(data.id), data)

            res({})
        } catch (error) {
            rej(error)
        }
    })
}

/**
 * @desc To delete device
 * @public
 */
export const deleteHmdDevice = (deviceId) => {
    return new Promise(async (res, rej) => {
        try {
            const { data } = await Axios.delete(API_CONSTANTS.HMD_DEVICE.DELETE_DEVICE(deviceId))

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}


/**
 * @desc To check device status mismatch
 * @public
 */
export const checkHmdDeviceMismatch = (deviceId, assessmentId) => {
    return new Promise(async (res, rej) => {
        try {
            const { data } = await Axios.get(API_CONSTANTS.HMD_DEVICE.CHECK_DEVICE_MISMATCH(deviceId, assessmentId))

            res({
                data: data.data,
                statusCode: data.statusCode,
                message: data.message
            })
        } catch (error) {
            rej(error)
        }
    })
}
