import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { setModalWithType } from '../../../actions/modal.action.js';
import {
  getBluetoothStatus,
  getBluetoothConnectionStatus,
  connectToBluetooth,
  forgetBluetoothConnection,
  manageBluetoothStatus,
  getBluetoothNetworksList,
} from '../../../actions/wifiBluetoothConnection.action.js';
import CustomTooltip from '../tooltip.js';
import { TOOLTIP_TEXTS } from '../../../shared/constants/shared.constants.js';

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  mac_address: Yup.string('Please select a bluetooth device').required(
    'Please select a bluetooth device',
  ),
});

export const BluetoothConnectionModal = props => {
  const [initValue, setInitValue] = useState({ mac_address: '' });
  const [bluetoothEnabled, setBluetoothEnabled] = useState(
    props.bluetoothStatus,
  );
  const [bluetoothConnected, setBluetoothConnected] = useState(
    props.isBluetoothConnected,
  );

  const cancelClickHandler = () => {
    props.setModalWithType(false);
  };

  const fetchNetworkData = async () => {
    await props.getBluetoothNetworksList();
    await props.getBluetoothStatus();
    await props.getBluetoothConnectionStatus();
  };

  const isBluetoothConnected = btDevice => {
    return (
      props.connectedBluetoothDevice &&
      props.connectedBluetoothDevice.some(
        x => x.mac_address === btDevice.mac_address,
      )
    );
  };

  // useEffect(() => {
  //   fetchNetworkData();

  //   const interval = setInterval(fetchNetworkData, 10000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [props.getBluetoothNetworksList, props.getBluetoothStatus, props.getBluetoothConnectionStatus]);

  useEffect(() => {
    setInitValue({ mac_address: props.connectedBluetoothDevice });
  }, [props.connectedBluetoothDevice]);

  useEffect(() => {
    // setBluetoothConnected(props.isBluetoothConnected);
    setBluetoothEnabled(props.bluetoothStatus);
  }, [props.isBluetoothConnected, props.bluetoothStatus]);

  const handleBluetoothToggle = async () => {
    if (bluetoothEnabled) {
      await props.manageBluetoothStatus(false);
    } else {
      await props.manageBluetoothStatus(true);
    }
    setBluetoothEnabled(prev => !prev);
    await fetchNetworkData();
  };

  const handleForgetConnection = async btDevice => {
    await props.forgetBluetoothConnection(btDevice);
    await fetchNetworkData();
  };

  const handleDisconnect = async values => {
    await props.connectToBluetooth({
      connect: false,
      ...values,
    });
    await fetchNetworkData();
  };

  const handleConnectionToggle = async () => {};

  const onSubmit = async (values, { setSubmitting }) => {
    await props.connectToBluetooth({
      connect: true,
      ...values,
    });
    await fetchNetworkData();
    setSubmitting(false);
  };

  const connectBluetooth = async btDevice => {
    await props.connectToBluetooth({
      connect: true,
      mac_address: btDevice.mac_address,
    });
    await fetchNetworkData();
  };

  const disconnectBluetooth = async btDevice => {
    await props.connectToBluetooth({
      connect: false,
      mac_address: btDevice.mac_address,
    });
    await fetchNetworkData();
  };

  return (
    <React.Fragment>
      <div className="modal-header">
        <h2 className="modal-title" id="patient-modalLabel">
          Bluetooth Connector
        </h2>
        <div>
          <button
            type="button"
            className="close focus-none"
            data-dismiss="modal"
            aria-label="Close"
            onClick={cancelClickHandler}
          >
            <CustomTooltip text={TOOLTIP_TEXTS.REFRESH}>
              <span aria-hidden="true">
                <i className="fabicon-close"></i>
              </span>
            </CustomTooltip>
          </button>
          <button
            type="button"
            className="close focus-none"
            // data-dismiss="modal"
            aria-label="Refresh"
            onClick={fetchNetworkData}
          >
            <span aria-hidden="true">
              <i className="fabicon-refresh"></i>
            </span>
          </button>
        </div>
      </div>

      <div className="modal-body">
        <div className="mt-3 test__content--scroll mcustomscrollbar">
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active">
              <Formik
                initialValues={initValue}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, handleSubmit, handleChange, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <div className="d-flex justify-content-start">
                        <label className="w-25">Bluetooth:</label>
                        <div className="onoffswitch">
                          <input
                            type="checkbox"
                            name="bluetoothToggle"
                            className="onoffswitch-checkbox"
                            id="bluetoothToggle"
                            tabIndex="0"
                            checked={bluetoothEnabled}
                            onChange={() => handleBluetoothToggle(values)}
                          />
                          <label
                            className="onoffswitch-label"
                            htmlFor="bluetoothToggle"
                          >
                            <span className="onoffswitch-inner content-wifi"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-start">
                        <label className="w-25">Connected:</label>
                        <label>
                          {props.isBluetoothConnected ? 'Yes' : 'No'}
                        </label>
                      </div>
                      <label htmlFor="mac_address">
                        Available Bluetooth Device:
                      </label>
                      <div className="filter-equal w-100 p-0">
                        {props.bluetoothNetworks.map((btDevice, index) => (
                          <div
                            key={index}
                            className="d-flex justify-content-between align-items-center mb-2"
                          >
                            <p>{btDevice.name}</p>
                            <div>
                              {isBluetoothConnected(btDevice) ? (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-red mr-2"
                                    onClick={() =>
                                      handleForgetConnection(btDevice)
                                    }
                                  >
                                    Remove Device
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-red"
                                    onClick={() =>
                                      disconnectBluetooth(btDevice)
                                    }
                                  >
                                    Disconnect
                                  </button>
                                </>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-red"
                                  onClick={() => connectBluetooth(btDevice)}
                                >
                                  ⠀Connect⠀
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalWithType,
      getBluetoothNetworksList,
      getBluetoothStatus,
      getBluetoothConnectionStatus,
      connectToBluetooth,
      forgetBluetoothConnection,
      manageBluetoothStatus,
    },
    dispatch,
  );

const mapStateToProps = ({ networks }) => ({
  bluetoothNetworks: networks.bluetoothList,
  bluetoothStatus: networks.bluetoothStatus,
  isBluetoothConnected: networks.isBluetoothConnected,
  connectedBluetoothDevice: networks.connectedBluetoothDevice,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BluetoothConnectionModal);
