import React from 'react'
import ReactDOM from 'react-dom'

import "./i18n"
import 'rc-slider/assets/index.css'
import './index.css'
import App from './App'

require('./middlewares/http-interceptor')

ReactDOM.render(
    <App />
, document.getElementById('root'))
